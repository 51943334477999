import { FC, useEffect, useRef, useState} from 'react'
import { JitsiMeeting } from '@jitsi/react-sdk';

const Room: FC = () => {
    return(
        <div className="">qwd</div>
    )

};

export default Room;
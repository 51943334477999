import { FC, useEffect, useState } from 'react';
import CheckBox from './Main/Checkbox/CheckBox.tsx';
import { EWeekDays, UserSchedule } from '../../models/User.ts';
import {
  ITranslateItemString,
  translations,
} from '../../utils/translations.tsx';
import { useAppSelector } from '../../hooks/redux.ts';
import { isUserDirector, UserRoles } from '../../utils/userRoles.ts';
import copy from 'copy-to-clipboard';

export interface ICalendarItem {
  id: number;
  time: string;
  pn: boolean;
  vt: boolean;
  sr: boolean;
  ct: boolean;
  pt: boolean;
  sb: boolean;
  vs: boolean;
}

interface GraphicCalendarProps {
  calendar: ICalendarItem[];
  disabled?: boolean;
  calendarError: string;
  setCalendar: (
    clb: ((state: ICalendarItem[]) => ICalendarItem[]) | ICalendarItem[],
  ) => void;
}

export type IDayItem = { [key in EWeekDays]?: boolean };

export const createCalendarGraphic = (timeFrom = 5, timeTo = 23) => {
  const calendar: ICalendarItem[] = [];
  let index = 0;

  for (let i = timeFrom; i <= timeTo; i++) {
    const item = (time: string): ICalendarItem => ({
      id: index,
      time,
      [EWeekDays.PN]: false,
      [EWeekDays.VT]: false,
      [EWeekDays.SR]: false,
      [EWeekDays.CT]: false,
      [EWeekDays.PT]: false,
      [EWeekDays.SB]: false,
      [EWeekDays.VS]: false,
    });
    calendar.push(item(`${i}:00`));
    index++;
    if (i < timeTo) {
      calendar.push(item(`${i}:30`));
      index++;
    }
  }

  return calendar;
};

const {
  aboutGraphicTitleText,
  pleaseWriteScheduleText,
  aboutGroupLessonsText,
  groupScheduleTimeText,
  aboutIndLessonsText,
  moscowTimeText,
  copyText,
  copiedText,
  monDayText,
  tueDayText,
  wedDayText,
  thuDayText,
  friDayText,
  satDayText,
  sunDayText,
} = translations.profile;

const { selectAllText } = translations.access;

const calendarDayTexts: { [key in EWeekDays]: ITranslateItemString } = {
  [EWeekDays.PN]: monDayText,
  [EWeekDays.VT]: tueDayText,
  [EWeekDays.SR]: wedDayText,
  [EWeekDays.CT]: thuDayText,
  [EWeekDays.PT]: friDayText,
  [EWeekDays.SB]: satDayText,
  [EWeekDays.VS]: sunDayText,
};

export const getUSerSchedule = (
  calendar: ICalendarItem[],
  schedule: UserSchedule[],
) => {
  const calendarCopy = [...calendar];
  schedule.forEach((item) => {
    const calendarItemIndex = calendar.findIndex(
      (col) => col.time === item.time,
    );
    if (calendarItemIndex !== -1) {
      const daysObj: IDayItem = item.days.reduce((acc: IDayItem, cur) => {
        acc[cur] = true;
        return acc;
      }, {});

      calendarCopy[calendarItemIndex] = {
        ...calendarCopy[calendarItemIndex],
        ...daysObj,
      };
    }
  });
  return calendarCopy;
};

const GraphicCalendar: FC<GraphicCalendarProps> = ({
  calendar,
  calendarError,
  setCalendar,
  disabled,
}) => {
  const language = useAppSelector((state) => state.TranslateSlice.language);
  const user = useAppSelector((state) => state.UserSlice.user);
  const [copied, setCopied] = useState<boolean>(false);
  const values: string[][] = Object.keys(calendarDayTexts)
    .map((day) => {
      let from: ICalendarItem | null = null;
      let to: ICalendarItem | null = null;
      const arr = calendar.reduce(
        (acc: { from: string; to: string }[], cur, index) => {
          if (index !== calendar.length - 1) {
            if (!from && cur[day as keyof ICalendarItem]) from = cur;
            if (
              cur[day as keyof ICalendarItem] &&
              !calendar[index + 1]?.[day as keyof ICalendarItem]
            ) {
              to = cur;
              if (from)
                acc.push({ from: from.time, to: calendar[to.id + 1].time });
              from = null;
            }
          }
          return acc;
        },
        [],
      );
      return [
        calendarDayTexts[day as EWeekDays][language],
        arr.map((item) => `(${item.from} - ${item.to})`).join(', '),
      ];
    })
    .filter((item) => item[1]);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 1000);
  }, [copied]);

  const onChange = (day: string, index: number) => {
    if (disabled) return;
    setCalendar((state) =>
      state.map((item) =>
        item.id === index
          ? {
              ...item,
              [day]: !item[day as keyof ICalendarItem],
            }
          : item,
      ),
    );
  };

  const onCopy = () => {
    copy(valuesText);
    setCopied(true);
  };

  const valuesText = values
    .map((item) => `${item[0]} - ${item[1]}`)
    .join(`,\n`);
  const halfOfData = Math.floor(calendar.length / 2);

  const calendarFirstPart = calendar.slice(0, halfOfData);
  const calendarSecPart = calendar.slice(halfOfData);

  const calendarHead = (index: 0 | 1) =>
    Object.keys(calendarDayTexts).map((day) => {
      const parts = [calendarFirstPart, calendarSecPart];

      const checkedArr = !index
        ? parts[index]
        : parts[index].slice(0, parts[index].length - 1);
      const checked = checkedArr.every(
        (item) => item[day as keyof ICalendarItem],
      );

      const onChange = () => {
        let payload = [];
        if (index === 0) {
          payload = [
            ...calendarFirstPart.map((item) => ({ ...item, [day]: !checked })),
            ...calendarSecPart,
          ];
        } else {
          payload = [
            ...calendarFirstPart,
            ...calendarSecPart.map((item, index) =>
              index === calendarSecPart.length - 1
                ? item
                : { ...item, [day]: !checked },
            ),
          ];
        }
        setCalendar(payload);
      };

      return (
        <div className={'flex flex-col gap-2 pb-[20px]'}>
          <p className="px-3 w-[56px] font-bold text-red-600" key={day}>
            {calendarDayTexts[day as EWeekDays][language]}
          </p>
          <CheckBox
            checked={checked}
            isImportant={true}
            onChange={onChange}
            wrapperClass="h-[28px] flex items-center justify-center"
          />
        </div>
      );
    });
  return (
    <>
      <div className="w-full flex flex-col">
        {((!disabled && user?.role === UserRoles.STUDENT) ||
          isUserDirector(user?.role)) && (
          <>
            <h2 className={'text-center mb-[10px] text-[20px] font-bold'}>
              {aboutGraphicTitleText[language]}
            </h2>
            <p className={'text-center mb-[10px] text-[16px] font-medium'}>
              {pleaseWriteScheduleText[language][0]}{' '}
              <span className={'text-red-600 font-bold'}>
                {pleaseWriteScheduleText[language][1]}
              </span>{' '}
              {pleaseWriteScheduleText[language][2]}
            </p>
            <p className={'text-center mb-[10px] text-[16px] font-medium'}>
              <span className={'text-red-600 font-bold'}>
                {aboutGroupLessonsText[language][0]}
              </span>{' '}
              {aboutGroupLessonsText[language][1]}
            </p>
            <p className={'text-center mb-[10px] text-[16px] font-medium'}>
              {groupScheduleTimeText[language]}
            </p>
            <p className={'text-center mb-[10px] text-[16px] font-medium'}>
              <span className={'text-red-600 font-bold'}>
                {aboutIndLessonsText[language][0]}
              </span>{' '}
              {aboutIndLessonsText[language][1]}
            </p>
          </>
        )}

        <div>
          <div
            className={
              'w-full flex items-center justify-between gap-4 overflow-x-auto'
            }
          >
            <div className="flex flex-col  items-center ">
              <div className={'flex  w-full justify-end items-center '}>
                <span
                  className={'text-[14px] mt-[10px] text-center max-w-[70px]'}
                >
                  {selectAllText[language]}
                </span>
                {calendarHead(0)}
              </div>
              {calendarFirstPart.map((item, indx) => (
                <div key={indx} className={'w-fit flex'}>
                  <p className="px-3 py-1 min-w-[70px]">{item.time}</p>
                  {Object.keys(calendarDayTexts).map((day) => (
                    <div
                      className="px-3 flex justify-center items-center"
                      key={day}
                    >
                      <CheckBox
                        checked={!!item[day as keyof ICalendarItem]}
                        onChange={() => onChange(day, indx)}
                        wrapperClass="h-[28px] flex items-center justify-center"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {!!values.length && (
              <div>
                <h2
                  className={
                    'text-center text-[20px] font-bold text-red-600 mb-[10px]'
                  }
                >
                  {moscowTimeText[language]}
                </h2>
                <div
                  className={
                    'flex flex-col gap-3 min-w-[200px] w-full border-2 p-2 rounded-[20px] border-black'
                  }
                >
                  {values.map(([day, times], indx) => (
                    <p key={indx} className={'text-[18px]'}>
                      <strong className={'font-extrabold text-red-600'}>
                        {day}:
                      </strong>{' '}
                      {times}
                    </p>
                  ))}
                  <button
                    className={'font-bold text-blue-500'}
                    type={'button'}
                    onClick={onCopy}
                  >
                    {(copied ? copiedText : copyText)[language]}
                  </button>
                </div>
              </div>
            )}

            <div className="flex flex-col  items-center">
              <div className={'flex w-full justify-end items-center'}>
                <span
                  className={'text-[14px] mt-[10px] text-center max-w-[70px]'}
                >
                  {selectAllText[language]}
                </span>

                {calendarHead(1)}
              </div>
              {calendarSecPart
                .slice(0, calendarSecPart.length - 1)
                .map((item, indx) => (
                  <div key={indx} className={'w-fit flex'}>
                    <p className="px-3 py-1 min-w-[70px]">{item.time}</p>
                    {Object.keys(calendarDayTexts).map((day) => (
                      <div
                        className="px-3 flex justify-center items-center"
                        key={day}
                      >
                        <CheckBox
                          checked={!!item[day as keyof ICalendarItem]}
                          onChange={() => onChange(day, indx + halfOfData)}
                          wrapperClass="h-[28px] flex items-center justify-center"
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <p className="text-red-500 font-bold text-lg">{calendarError || ''}</p>
      </div>
    </>
  );
};

export default GraphicCalendar;

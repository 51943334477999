import { FC, useState } from 'react';
import { ITheme } from '../../models/Program/ITheme';
import Folder from '../../assets/icons/folder.png';
import FolderGreen from '../../assets/icons/folder-green.png';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getMaterials, getThemes } from '../../store/reducers/ProgramSlice';
import { setTheme } from '../../store/reducers/ProgramSlice';
import { Reorder, useDragControls } from 'framer-motion';
import { Menu } from '@headlessui/react';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import ProgramService from '../../services/ProgramService';
import { useParams } from 'react-router-dom';
import { FaComment } from '@react-icons/all-files/fa/FaComment';
import CommentFirstModal from '../OnlineLesson/Program/Modals/CommentFirstModal';

interface ThemeProps {
  theme: ITheme;
  active: string;
  setActive: (value: string) => void;
  editHandler: (theme: ITheme) => void;
  removeHandler: (theme: ITheme) => void;
}

interface DragDropItem {
  order: number;
  _id: string;
}

const Theme: FC<ThemeProps> = ({
  theme,
  active,
  setActive,
  editHandler,
  removeHandler,
}) => {
  const dispatch = useAppDispatch();
  const controls = useDragControls();
  const { user } = useAppSelector((state) => state.UserSlice);
  const { themes } = useAppSelector((state) => state.ProgramSlice);
  const numberList = +`${themes[0].level}00` + themes.indexOf(theme) + 1;
  const [isPressed, setIsPressed] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const [oldArr, setOldArr] = useState<ITheme[]>([]);
  const param = useParams();
  const isParams = !param.groupId;
  const [firstCommentModal, setFirstCommentModal] = useState(false);
  const [commentCount, setCommentCount] = useState();

  const fetchMaterials = async () => {
    setActive(theme._id);
    dispatch(setTheme(theme._id));
    await dispatch(getMaterials(theme._id));
  };

  const openFirstModal = (): void => setFirstCommentModal(true);

  const DragEndHandler = async (id: string) => {
    const seq = themes.findIndex((theme) => theme._id === id);
    const dropItem: DragDropItem = oldArr[seq];
    const dragItem: DragDropItem = oldArr[dragStart];

    const resOrder =
      dragItem.order > dropItem.order
        ? dropItem.order - 0.1
        : dropItem.order + 0.1;

    await ProgramService.editTheme(
      dragItem._id,
      undefined,
      undefined,
      undefined,
      resOrder,
    );
    await dispatch(getThemes({ level: theme.level }));
  };

  const DragStartHandler = (id: string) => {
    const seq = themes.findIndex((theme) => theme._id === id);
    setOldArr(themes);
    setDragStart(seq);
  };

  const toggleLine = async (type: string, button: number) => {
    if (!isParams) {
      return;
    }

    if (type === 'click' && button === 0) {
      if (!isPressed) {
        setIsPressed(true);
        setTimeout(() => {
          setIsPressed(false);
        }, 300);
      } else {
        const line = theme.line === 'yas' ? 'no' : 'yas';
        await ProgramService.editTheme(theme._id, line);
        await dispatch(getThemes({ level: theme.level }));
      }
    }
  };

  return (
    <Reorder.Item
      dragListener={isParams}
      dragControls={
        user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR'
          ? controls
          : undefined
      }
      key={theme._id}
      value={theme}
      onDragEnd={() => void DragEndHandler(theme._id)}
      onDragStart={() => void DragStartHandler(theme._id)}
    >
      <button
        className={[
          'flex items-center w-full  mb-2 relative [&>.arr-menu]:hover:block',
          'yas' === theme.line ? 'pb-2 border-b-8 border-green-500' : '',
        ].join(' ')}
      >
        <span
          onClick={(e) => void toggleLine(e.type, e.button)}
          className="rounded-full bg-red-400 p-2 mr-2"
        >
          {numberList}
        </span>
        <p
          onClick={() => void fetchMaterials()}
          className={[
            'w-full rounded-full py-2 flex items-center justify-center font-bold text-sm border-2 ',
            active === theme._id
              ? 'bg-[#1EA413] border-[#1EA413] text-white'
              : 'border-[#C4C4C4] bg-white text-[#353535]',
          ].join(' ')}
        >
          {theme.name}
        </p>

        {user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR' ? (
          <Menu
            as="div"
            className="absolute right-2 top-1/2 z-10 -translate-y-1/2 arr-menu hidden"
          >
            <Menu.Button as="button" className="text-gray-800 text-xl">
              <IoIosArrowDown />
            </Menu.Button>
            <Menu.Items
              as="div"
              className="absolute right-0 border bg-white w-[180px] rounded-2xl flex flex-col border-[#B7975A] overflow-hidden"
            >
              <Menu.Item
                as="button"
                onClick={() => editHandler(theme)}
                className="border-b border-[#B7975A] py-1 font-medium hover:bg-[#B7975A] hover:text-white"
              >
                Change studio
              </Menu.Item>
              <Menu.Item
                as="button"
                onClick={() => removeHandler(theme)}
                className="border-b border-[#B7975A] py-1 font-medium hover:bg-[#B7975A] hover:text-white"
              >
                Delete studio
              </Menu.Item>
              <Menu.Item
                as="button"
                className="py-1 font-medium hover:bg-[#B7975A] hover:text-white"
                onClick={openFirstModal}
              >
                Comments
              </Menu.Item>
            </Menu.Items>
          </Menu>
        ) : (
          <div className="absolute right-2 top-1/2 z-10 -translate-y-1/2 arr-menu hidden">
            {/* <button onClick={openFirstModal}>
              <FaComment />
            </button> */}
          </div>
        )}
      </button>
      <CommentFirstModal
        openModal={firstCommentModal}
        setOpenModal={setFirstCommentModal}
        groupId={theme._id}
        type={'STUDIO'}
      />
    </Reorder.Item>
  );
};

export default Theme;

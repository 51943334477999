import { FC, PropsWithChildren } from 'react';

interface MainButtonProps {
  className?: string;
  onClick?: () => void;
  type?: string;
  style?: React.CSSProperties;
}

const MainButton: FC<PropsWithChildren<MainButtonProps>> = ({
  children,
  className,
  onClick,
  type,
  style,
}) => {
  return (
    <button
      type={type ? 'button' : 'submit'}
      onClick={onClick}
      style={style}
      className={[
        className,
        'justify-center w-full bg-gradient-menu text-white text-xl font-semibold py-1 md:py-3 px-2 md:px-4 rounded-full',
      ].join(' ')}
    >
      {children}
    </button>
  );
};

export default MainButton;

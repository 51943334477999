// import React from 'react'
import { AiOutlinePlus } from "@react-icons/all-files/ai/AiOutlinePlus";
import { FaUser } from "@react-icons/all-files/fa6/FaUser";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalChat from "../../Lessons/ModalChat/ModalChat";
import { ITopMenu } from '../../../models/ITopMenu';
import TopMenu from '../../../components/UI/TopMenu/TopMenu';
import { IoChatboxEllipsesOutline } from "@react-icons/all-files/io5/IoChatboxEllipsesOutline";
import { useAppDispatch } from "../../../hooks/redux";
import { getAllSeans } from "../../../store/reducers/SeansSlice";

const table = [
  {
    _id: 1,
    title: "Hrachya777 simul 1",
    description: "20+60 Standart",
    seanser: "Hrachya777",
    date: "2021",
    playersCount: 5,
  },
  {
    _id: 2,
    title: "Hrachya777 simul 2",
    description: "20+60 Standart",
    seanser: "Hrachya777",
    date: "2021",
    playersCount: 5,
  },
  {
    _id: 3,
    title: "Hrachya777 simul 3",
    description: "20+60 Standart",
    seanser: "Hrachya777",
    date: "2021",
    playersCount: 5,
  },
];

const CreateSessionPage = () => {
  const dispatch = useAppDispatch();
  const [chat, setChat] = useState(false);
  const { groupId } = useParams();
  const [menu] = useState<ITopMenu[]>([
    { id: 0, name: 'Программа', path: `/group/${groupId}/program`, scope: ['DIRECTOR', 'ZDIRECTROR', 'TRANER', groupId === '653bb23a7575d7142fe229e7' ? 'ADMIN' : ''] }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
    { id: 1, name: 'Онлайн урок', path: `/group/${groupId}/online-lesson`, scope: ['DIRECTOR', 'ZDIRECTROR', 'TRANER', groupId === '653bb23a7575d7142fe229e7' ? 'ADMIN' : ''] }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
    { id: 2, name: 'Домашнее\nзадание', path: `/group/${groupId}/homework`, scope: ['DIRECTOR', 'ZDIRECTROR', 'TRANER', groupId === '653bb23a7575d7142fe229e7' ? 'ADMIN' : ''] }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
    { id: 3, name: 'Ученики', scope: ['DIRECTROR', 'ZDIRECTROR', 'TRANER', groupId === '653bb23a7575d7142fe229e7' ? 'ADMIN' : ''] },
    { id: 4, name: 'Описание группы', path: `/group/${groupId}/description` }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
    { id: 5, name: 'История', path: `/group/${groupId}/history` }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
  ]);


  useEffect(()=>{
    dispatch(getAllSeans());
    // console.log()
  },[])

  return (
    <>
      <TopMenu menu={menu} />
      <div className="p-5" >
        <div className="bg-gray-100 rounded-3xl" >
          <table className="w-full bg-[#f0f0f0] rounded-xl border-collapse ">
            <caption className="text-4xl font-semibold py-8" >Сеанс одновременной игры</caption>
            <thead className="bg-gray-300" >
              <tr>
                <td className="text-center font-semibold text-3xl py-1" >Ваши предстоящие сеансы</td>
                <td className="text-center font-semibold text-3xl py-1" >Сеансёр</td>
                <td className="text-center font-semibold text-3xl py-1" >Игроки</td>
              </tr>
            </thead>
            <tbody>
              {table &&
                table.map((item) => (
                  <tr key={item._id} className="border-b-2 border-b-[#C4C4C4]">
                    <td
                      className="text-center p-3"
                    >
                      <Link className="text-blue-400" to={`/`}>
                        <p className="text-4xl font-semibold">{item.title}</p>
                        <p className="text-xl font-normal mt-2">
                          {item.description}
                        </p>
                      </Link>
                    </td>
                    <td
                      className="text-center p-3"
                    >
                      <p className="text-2xl">{item.seanser}</p>
                      <p className="font-semibold text-3xl mt-2">{item.date}</p>
                    </td>
                    <td className="p-3">
                      <div className="flex flex-row items-center text-3xl">
                        <FaUser className="mr-3" />
                        <span>{item.playersCount}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="flex items-center justify-between pb-8 px-9 pt-4">
            <Link to={'table'} className='mt-5 bg-gradient-button text-2xl font-semibold rounded-full py-4 px-12 hover:bg-gradient-red flex items-center hover:text-white' >
              Результаты лиги
            </Link>
            <Link to={`create`} className='mt-5 text-2xl font-semibold rounded-full py-4 px-12 bg-gradient-appricot flex items-center hover:underline' >
              <span className="text-red-600 block">(не готово)</span>
              Создать сеанс <AiOutlinePlus className="ml-5" />
            </Link>
            <button className="flex flex-row items-center mt-5" onClick={() => setChat(prev => !prev)} >
              <span className="shadow-md inline-block rounded-full p-1 px-3 mr-2 font-semibold" >{chat ? "Закрыть" : "Открыть"} чат группы  </span>
              <span className="shadow-md bg-gradient-button text-2xl font-semibold rounded-full py-2 px-4 inline-block items-center" ><IoChatboxEllipsesOutline /></span>
            </button>
          </div>
        </div>
        {chat &&
          <div className="max-w-[900px] p-[10px] m-auto">
            <ModalChat className='w-full 2xl:w-full' contentHeight={300} />
          </div>
        }
      </div>
    </>
  );
};

export default CreateSessionPage;

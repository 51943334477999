import {FC, useEffect} from "react";
import {IRequisite} from "../../../models/IRequisite.ts";
import Modal from "../../UI/Modal.tsx";
import {translations} from "../../../utils/translations.tsx";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux.ts";
import {useFormValue} from "../../../hooks/useFormValue.ts";
import Input from "../../UI/Main/Input.tsx";
import {createSelectOptionsFromObj} from "../../../utils/createSelectOptionsFromObj.ts";
import {userCountries} from "../../../constants.ts";
import Select from "../../UI/Main/Select.tsx";
import {ISelect} from "../../../models/ISelect.ts";
import {createRequisite, editRequisite} from "../../../store/reducers/BalansSlice.ts";
import {ECountries} from "../../../models/User.ts";
import Button from "../../UI/Button.tsx";

interface AddEditBalanceModalProps {
    opened: boolean,
    onClose: (val: boolean) => void,
    editData: IRequisite | null
}

const enum EFormFields {
    bic = "bic",
    card = "card",
    ownerEn = "ownerEn",
    ownerRu = "ownerRu",
    cardType = "cardType",
    countries = "countries",
    expireDate = "expireDate",
    accountNumber = "accountNumber",
}

interface FormProps {
    [EFormFields.bic]: string,
    [EFormFields.accountNumber]: string,
    [EFormFields.card]: string,
    [EFormFields.ownerEn]: string,
    [EFormFields.ownerRu]: string,
    [EFormFields.cardType]: string,
    [EFormFields.expireDate]: string,
    [EFormFields.countries]: ISelect[]
}




const {
    editRequisiteText,
    addRequisiteText,
    cardOwnerEnText,
    cardOwnerRuText,
    cardNumberText,
    bicText,
    accountNumberText,
    cardTypeText,
    validThruText
} = translations.requisites

const {
    countryText,
    saveText,
    fieldMustFilledText
} = translations.profile

const AddEditBalanceModal: FC<AddEditBalanceModalProps> = ({opened, onClose, editData}) => {
    const dispatch = useAppDispatch()
    const language = useAppSelector(state => state.TranslateSlice.language)
    const countryOptions = createSelectOptionsFromObj(userCountries, language)

    const initialData: FormProps = {
        [EFormFields.bic]: "",
        [EFormFields.accountNumber]: "",
        [EFormFields.card]: "",
        [EFormFields.ownerEn]: "",
        [EFormFields.ownerRu]: "",
        [EFormFields.cardType]: "",
        [EFormFields.expireDate]: "",
        [EFormFields.countries]: []
    }

    const {
        formData,
        onChange,
        setValue,
        handleSubmit,
        error,
        setFieldError,
        clearInputError,
        setFormData
    } = useFormValue(initialData, [EFormFields.bic, EFormFields.accountNumber,EFormFields.expireDate])


    useEffect(() => {
        if(editData) {
            setFormData({
                [EFormFields.bic]: editData?.bic || "",
                [EFormFields.accountNumber]: editData?.accountNumber || "",
                [EFormFields.card]: editData?.card || "",
                [EFormFields.ownerEn]: editData?.ownerEn || "",
                [EFormFields.ownerRu]: editData?.ownerRu || "",
                [EFormFields.cardType]: editData?.cardType || "",
                [EFormFields.expireDate]: editData?.expireDate || "",
                [EFormFields.countries]: countryOptions.filter(item => editData?.countries.includes(item.slug as ECountries))
            })
        }
    }, [editData]);
    const onSelectChange = (val: ISelect[]) => {
        clearInputError(EFormFields.countries)

        if(error[EFormFields.accountNumber]) clearInputError(EFormFields.accountNumber)
        else if(error[EFormFields.bic]) clearInputError(EFormFields.bic)
        setValue(EFormFields.countries, val);
    }

    const onSubmit = async (data: FormProps) => {
        if (!data[EFormFields.bic] && !data[EFormFields.accountNumber]) {
            const errKey =
                data[EFormFields.countries] && data[EFormFields.countries].find(item => item.slug === ECountries.ARMENIA)
                    ? EFormFields.accountNumber
                    : EFormFields.bic
            setFieldError(errKey, fieldMustFilledText)

            return;
        }

        const payload = {
            ...data,
            countries: data[EFormFields.countries]?.map(item => item.slug) || [] as ECountries[]
        }
        try {
            if(!editData) {
                await dispatch(createRequisite(payload))
            } else {
                await dispatch(editRequisite({payload,id: editData._id}))
            }
            onClose(false)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Modal setActive={onClose} active={opened} className={'max-w-[800px]'}>
            <h2 className={'text-[24px] font-bold mb-3 dark:text-white text-center'}>
                {(editData ? editRequisiteText : addRequisiteText)[language]}
            </h2>

            <form className={'flex flex-col gap-6'} onSubmit={(e) => handleSubmit(e, onSubmit)}>
                <Input
                    onChange={onChange}
                    placeholder={cardOwnerRuText[language]}
                    error={error[EFormFields.ownerRu]?.[language]}
                    value={formData[EFormFields.ownerRu]}
                    name={EFormFields.ownerRu}
                />
                <Input
                    onChange={onChange}
                    placeholder={cardOwnerEnText[language]}
                    error={error[EFormFields.ownerEn]?.[language]}
                    value={formData[EFormFields.ownerEn]}
                    name={EFormFields.ownerEn}
                />
                <Input
                    onChange={onChange}
                    placeholder={cardNumberText[language]}
                    maxLength={16}
                    minLength={16}
                    error={error[EFormFields.card]?.[language]}
                    value={formData[EFormFields.card]}
                    name={EFormFields.card}
                />
                <Input
                    onChange={onChange}
                    placeholder={bicText[language]}
                    error={error[EFormFields.bic]?.[language]}
                    value={formData[EFormFields.bic]}
                    name={EFormFields.bic}
                />
                <Input
                    onChange={onChange}
                    placeholder={accountNumberText[language]}
                    error={error[EFormFields.accountNumber]?.[language]}
                    value={formData[EFormFields.accountNumber]}
                    name={EFormFields.accountNumber}
                />
                <Input
                    onChange={onChange}
                    placeholder={validThruText[language]}
                    error={error[EFormFields.expireDate]?.[language]}
                    value={formData[EFormFields.expireDate]}
                    name={EFormFields.expireDate}
                />
                <Input
                    onChange={onChange}
                    placeholder={cardTypeText[language]}
                    error={error[EFormFields.cardType]?.[language]}
                    value={formData[EFormFields.cardType]}
                    name={EFormFields.cardType}
                />
                <Select
                    multiple={true}
                    error={error[EFormFields.countries]?.[language]}
                    className='select'
                    wrapperClass='w-full'
                    name={`${countryText[language]}:`}
                    options={countryOptions}
                    value={formData[EFormFields.countries]}
                    onChange={onSelectChange}/>

                <Button>{saveText[language]}</Button>
            </form>
        </Modal>
    );
}

export default AddEditBalanceModal;
import copy from 'copy-to-clipboard';
import { FC, useState } from 'react';
import Modal from '../UI/Modal.tsx';

import copyImg from '../../assets/icons/copy.png';
import messengerImg from '../../assets/icons/messenger.png';
import telegramImg from '../../assets/icons/telegram.png';
import viberImg from '../../assets/icons/viber.png';
import whatsappImg from '../../assets/icons/whatsapp.png';
import { languages } from '../../constants.ts';
import { useAppSelector } from '../../hooks/redux.ts';
import { Elanguages } from '../../store/reducers/TranslateSlice.ts';
import { isDeviceMobile } from '../../utils/getDeviceType.ts';
import {
  ITranslateItemArray,
  ITranslateItemString,
  translations,
} from '../../utils/translations.tsx';

interface HomeRecomendModalProps {
  active: boolean;
  onClose: (val: boolean) => void;
}

const classNames = {
  title:
    'text-[#fdc800] font-normal text-center text-[30px] mb-[15px] px-[10px]',
  container: 'max-w-[700px] mx-auto px-[15px]',
  contentTxt: 'text-[16px] block text-white whitespace-pre-wrap leading-[1.6]',
};

const HomeRecomendModal: FC<HomeRecomendModalProps> = ({ active, onClose }) => {
  const { user } = useAppSelector((state) => state.UserSlice);

  const [isshareBtnsShowing, setIsshareBtnsShowing] = useState(false);
  const language = useAppSelector((state) => state.TranslateSlice.language);

  const [popupLanguage, setPopupLanguage] = useState<Elanguages>(language);
  const [isCopied, setIsCopied] = useState(false);

  const {
    recommendationTitleText,
    shareText,
    recommendationText,
    advantagesText,
    advantage1Text,
    advantage4Text,
    priceSiteText,
    registerForFreeLessonText,
    officialSiteText,
    instagramText,
    advantageLinkText,
    advantage4LinkText,
    copiedText,
  }: {
    recommendationTitleText: ITranslateItemString;
    shareText: ITranslateItemString;
    recommendationText: ITranslateItemString;
    advantagesText: ITranslateItemString;
    advantageLinkText: ITranslateItemString;
    advantage1Text: ITranslateItemArray;
    advantage4Text: ITranslateItemString;
    advantage4LinkText: ITranslateItemString;
    registerForFreeLessonText: ITranslateItemString;
    officialSiteText: ITranslateItemString;
    instagramText: ITranslateItemString;
    priceSiteText: ITranslateItemString;
    copiedText: ITranslateItemString;
  } = translations.recommendModal;

  const referrerData = `id:${user._id}, name:${user.name}, surname:${user.sname}, email:${user.email}`;
  const referrerUrl = `https://araratchess.ru/online?referrer=${btoa(
    escape(referrerData),
  )}&popup=free-lesson`;

  const shareFullText = `${recommendationText[popupLanguage]}!\n\n ${advantagesText[popupLanguage]}\n ${advantageLinkText[popupLanguage]} \n\n ${advantage1Text[popupLanguage][0]}${advantage1Text[popupLanguage][1]}\n ${advantage1Text[popupLanguage][2]}\n\n ${advantage4Text[popupLanguage]} ${advantage4LinkText[popupLanguage]} \n\n ${registerForFreeLessonText[popupLanguage]}\n ${priceSiteText[popupLanguage]}\n ${instagramText[popupLanguage]}\n\n ${officialSiteText[popupLanguage]}\n ${referrerUrl}`;

  const telegramShareText = shareFullText.replaceAll('*', '**');

  const toggleShareBtnsShowing = () =>
    setIsshareBtnsShowing((prevState) => !prevState);

  const copyText = () => {
    copy(shareFullText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const onLangChange = (lang: Elanguages) => {
    setPopupLanguage(lang);
  };

  const telegramLink = `https://telegram.me/share/url?url=${encodeURIComponent(
    telegramShareText,
  )}`;

  const whatsappLink = isDeviceMobile()
    ? `whatsapp://send?text=${shareFullText}`
    : 'https://web.whatsapp.com/';

  return (
    <Modal
      active={active}
      setActive={onClose}
      closeBtnStyle={'!text-black'}
      className={`${classNames.container} !p-[0px] !bg-white rounded-[20px]`}
    >
      <div>
        <div className={`${classNames.container}`}>
          <h2 className={classNames.title}>
            {recommendationTitleText[popupLanguage]}
          </h2>
          <div className="relative">
            <div className="flex items-center justify-between flex-col sm:flex-row gap-2 mb-2">
              <div className="flex gap-[6px] mb-10">
                {Object.values(languages).map((item) => (
                  <button
                    key={item.text}
                    onClick={() => onLangChange(item.text)}
                    className={`flex gap-[2px] p-[4px] items-center ${
                      popupLanguage === item.text
                        ? 'bg-[#ccc] rounded-full'
                        : ''
                    }`}
                  >
                    <img
                      src={item.img}
                      alt="language"
                      key={item.text}
                      className="rounded-full w-[20px]"
                    />
                    <span>{item.shortName}</span>
                  </button>
                ))}
              </div>
              <button
                onClick={toggleShareBtnsShowing}
                className="bg-[#e1e3e8] py-[10px] px-[20px] text-[#424242] text-[16px] font-medium block  border-0 max-w-[200px] w-full "
              >
                {shareText[popupLanguage]}
              </button>
            </div>

            <div
              className={`absolute bottom-[-5px] right-0 translate-y-[100%] rounded-[5px] px-[10px] py-[6px] bg-[#ddd] flex items-center gap-[8px] ${
                !isshareBtnsShowing ? 'hidden' : ''
              }`}
            >
              <button
                onClick={copyText}
                className="border-0 bg-transparent cursor-pointer relative"
              >
                <img src={copyImg} alt="copy" className="w-[25px]" />
                <span
                  className={`absolute top-[-20px] left-[50%] translate-x-[-50%] text-[14px] text-black translate-y-[-100%] whitespace-nowrap rounded-[5px] p-[6px] bg-[#ddd] transition text-center ${
                    isCopied ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  {copiedText[popupLanguage]}
                </span>
              </button>
              <button className="border-0 bg-transparent cursor-pointer">
                <a
                  target="_blank"
                  href={whatsappLink}
                  className="border-0 bg-transparent cursor-pointer"
                >
                  <img src={whatsappImg} alt="copy" className="w-[35px]" />
                </a>
              </button>
              <button className="border-0 bg-transparent cursor-pointer">
                <a target="_blank" href={telegramLink}>
                  <img src={telegramImg} alt="copy" className="w-[35px]" />
                </a>
              </button>
              <button className="border-0 bg-transparent cursor-pointer">
                <a
                  href={`viber://forward?text=${shareFullText}`}
                  target="_blank"
                >
                  <img src={viberImg} alt="copy" className="w-[35px]" />
                </a>
              </button>
              <button className="border-0 bg-transparent cursor-pointer">
                <a
                  target="_blank"
                  href={`fb-messenger://share?link=${shareFullText}`}
                >
                  <img src={messengerImg} alt="copy" className="w-[35px]" />
                </a>
              </button>
            </div>
          </div>
          <div className="text-[14px] block text-black whitespace-pre-wrap leading-[1.6] py-[10px]">
            {recommendationText[popupLanguage]}
            <br />
            <br />
            {advantagesText[popupLanguage]} <br />
            <a
              href={advantageLinkText[popupLanguage]}
              target="_blank"
              rel="noopener"
            >
              <p className="text-[#216ba5] mb-3">
                {advantageLinkText[popupLanguage]}
              </p>
            </a>
            <p className="mb-3">
              {advantage1Text[popupLanguage][0]}
              <strong>{advantage1Text[popupLanguage][1]}</strong>{' '}
              {advantage1Text[popupLanguage][2]}
            </p>
            <div className="flex">
              <p className="text-[#ff0000] font-bold">
                {advantage4Text[popupLanguage]}{' '}
              </p>
              <a
                href={advantage4LinkText[popupLanguage]}
                target="_blank"
                rel="noopener"
              >
                <p className="text-[#216ba5] mb-5">
                  {advantage4LinkText[popupLanguage]}
                </p>
              </a>
            </div>
            <p className="font-bold">
              {registerForFreeLessonText[popupLanguage]}
            </p>
            <p className="font-bold">{priceSiteText[popupLanguage]}</p>
            <p className="font-bold mb-2">{instagramText[popupLanguage]}</p>
            <p>{officialSiteText[popupLanguage]}</p>
            <a className="text-[#216ba5] break-all" href={referrerUrl}>
              {referrerUrl}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HomeRecomendModal;

import { FC, useState } from 'react';
import { languages } from '../../constants.ts';
import { useAppSelector } from '../../hooks/redux.ts';
import { Elanguages } from '../../store/reducers/TranslateSlice.ts';
import {
  ITranslateItemString,
  translations,
} from '../../utils/translations.tsx';
import Modal from '../UI/Modal.tsx';

interface AboutPromotionModalProps {
  active: boolean;
  onClose: (val: boolean) => void;
  onOpenRecomendModal: () => void;
}

const classNames = {
  title:
    'text-[#fdc800] font-normal text-center text-[30px] mb-[12px] px-[10px]',
  container: 'max-w-[700px] mx-auto px-[15px]',
  contentTxt: 'text-[16px] block text-white whitespace-pre-wrap leading-[1.6]',
};

const AboutPromotionModal: FC<AboutPromotionModalProps> = ({
  active,
  onClose,
  onOpenRecomendModal,
}) => {
  const language = useAppSelector((state) => state.TranslateSlice.language);

  const [popupLanguage, setPopupLanguage] = useState<Elanguages>(language);

  const {
    promotionTitleText,
    promotionTitleText2,
    promotionText1,
    promotionText2,
    promotionText4,
    promotionText5,
    promotionText6,
    promotionText7,
    promotionText8,
    promotionText9,
    promotionText10,
    promotionText11,
    promotionText12,
    promotionText13,
    promotionText14,
    promotionText15,
    promotionText16,
    promotionText17,
    promotionText18,
    promotionText19,
    promotionText20,
  }: {
    promotionTitleText: ITranslateItemString;
    promotionTitleText2: ITranslateItemString;
    promotionText1: ITranslateItemString;
    promotionText2: ITranslateItemString;
    promotionText4: ITranslateItemString;
    promotionText5: ITranslateItemString;
    promotionText6: ITranslateItemString;
    promotionText7: ITranslateItemString;
    promotionText8: ITranslateItemString;
    promotionText9: ITranslateItemString;
    promotionText10: ITranslateItemString;
    promotionText11: ITranslateItemString;
    promotionText12: ITranslateItemString;
    promotionText13: ITranslateItemString;
    promotionText14: ITranslateItemString;
    promotionText15: ITranslateItemString;
    promotionText16: ITranslateItemString;
    promotionText17: ITranslateItemString;
    promotionText18: ITranslateItemString;
    promotionText19: ITranslateItemString;
    promotionText20: ITranslateItemString;
  } = translations.aboutPromotionModal;

  const { copyBtnText } = translations.homepage;

  const onLangChange = (lang: Elanguages) => {
    setPopupLanguage(lang);
  };
  const handleRecommendButton = () => {
    onClose(false);
    onOpenRecomendModal();
  };

  return (
    <Modal
      active={active}
      setActive={onClose}
      closeBtnStyle={'!text-black'}
      className={`${classNames.container} !p-[0px] !bg-white rounded-[20px]`}
    >
      <div>
        <div className={`${classNames.container}`}>
          <h2 className={classNames.title}>
            {promotionTitleText[popupLanguage]}
          </h2>
          <div className="relative">
            <div className="flex items-center justify-between flex-col sm:flex-row gap-2 mb-2">
              <div className="flex gap-[6px] mb-4">
                {Object.values(languages).map((item) => (
                  <button
                    key={item.text}
                    onClick={() => onLangChange(item.text)}
                    className={`flex gap-[2px] p-[4px] items-center ${
                      popupLanguage === item.text
                        ? 'bg-[#ccc] rounded-full'
                        : ''
                    }`}
                  >
                    <img
                      src={item.img}
                      alt="language"
                      key={item.text}
                      className="rounded-full w-[20px]"
                    />
                    <span>{item.shortName}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <p className="text-[14px] block text-black whitespace-pre-wrap leading-[1.6]">
            {promotionTitleText2[popupLanguage]}
          </p>
          <p className="text-[14px] block text-[#ff0000] font-bold whitespace-pre-wrap leading-[1.6] mb-5">
            {promotionText1[popupLanguage]}
          </p>
          <p className="text-base block text-[#ff0000] font-bold whitespace-pre-wrap leading-[1.6]">
            {promotionText2[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6]">
            {promotionText4[popupLanguage]}{' '}
            <strong>{promotionText5[popupLanguage]}</strong>
            {promotionText6[popupLanguage]}
            <strong>{promotionText7[popupLanguage]}</strong>
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText8[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] ">
            {promotionText9[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] ">
            {promotionText10[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText11[popupLanguage]}
          </p>
          <p className="text-base block text-[#ff0000] font-bold whitespace-pre-wrap leading-[1.6]">
            {promotionText12[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText13[popupLanguage]}{' '}
            <strong>{promotionText14[popupLanguage]}</strong>
            {promotionText15[popupLanguage]}
          </p>
          <p className="text-base block text-[#ff0000] font-bold whitespace-pre-wrap leading-[1.6]">
            {promotionText16[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText17[popupLanguage]}
          </p>
          <p className="text-base block text-[#ff0000] font-bold whitespace-pre-wrap leading-[1.6]">
            {promotionText18[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText19[popupLanguage]}
          </p>
          <p className="text-[14px] text-black whitespace-pre-wrap leading-[1.6] mb-4">
            {promotionText20[popupLanguage]}
          </p>
          <div className="flex w-full mb-4 justify-center">
            <button
              onClick={handleRecommendButton}
              className="w-fit bg-gradient-button rounded-full mt-2 px-1 sm:px-5 py-2  items-center font-semibold shadow-lg hover:bg-gradient-appricot hover:text-black text-blue-500 text-[12px] sm:text-[14px] xl:text-[18px] leading-normal "
            >
              <p>{copyBtnText[popupLanguage]}</p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AboutPromotionModal;

import { FC } from 'react'

import Chats from '../../components/Messenger/Chats/Chats';
import StartMessaging from '../../components/Messenger/StartMessaging';

const MessengerPage: FC = () => {
    return (
        <div className='flex h-full w-full'>
            <Chats/>
            <StartMessaging/>
        </div>
    )
}

export default MessengerPage;
import { FC, useState, useEffect } from 'react'
import TopMenu from '../../components/UI/TopMenu/TopMenu';
import { ITopMenu } from '../../models/ITopMenu';
import Table from '../../components/MyGroups/Table';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getGroups } from '../../store/reducers/GroupSlice';
import {ITranslateItemString, translations} from "../../utils/translations.tsx";

import "./MyGroups.css"

const MyGroups: FC = () => {
    const language = useAppSelector(state => state.TranslateSlice.language)

    const {
        nameText,
        knowledgeLevelText,
        studentNumberText,
        startDateText,
    }: {
        nameText: ITranslateItemString,
        knowledgeLevelText: ITranslateItemString,
        studentNumberText: ITranslateItemString,
        startDateText: ITranslateItemString,
    } = translations.groups


    const [menu] = useState<ITopMenu[]>([
        {id: 0, name: nameText[language]},
        {id: 1, name: knowledgeLevelText[language]},
        {id: 2, name: studentNumberText[language]},
        {id: 3, name: startDateText[language]},
        {id: 4, name: 'Дата создания'},
        {id: 5, name: 'Дата архивации'},
    ])
    const dispatch = useAppDispatch();
    const { groups } = useAppSelector(state=> state.GroupSlice)
   
    const fetchData = async(archive?: boolean, noStudents?: boolean) => {
        // const response = await PermissionsService.getUsers(role, search, archive);
        // setUsers(response.data.users);
        await dispatch(getGroups({archive, noStudents}))
    }
    useEffect(() => {
        void fetchData();
    }, [dispatch])
    // const [table] = useState<IMyGropsTable[]>([
    //     {id: 0, name: 'Вс 12:30 4-7 МГ З.ЭД', level: '7+ 1000-1300 ПРОДВИНУТЫЙ', count: '5/6', start: '21.06.2023', description: 'ТЕКСТ'},
    //     {id: 1, name: 'Вс 12:30 4-7 МГ З.ЭД', level: '7+ 1000-1300 ПРОДВИНУТЫЙ', count: '5/6', start: '21.06.2023', description: 'ТЕКСТ'},
    //     {id: 2, name: 'Вс 12:30 4-7 МГ З.ЭД', level: '7+ 1000-1300 ПРОДВИНУТЫЙ', count: '5/6', start: '21.06.2023', description: 'ТЕКСТ'},
    // ])
    return (
        <div className='w-full h-full'>
            <TopMenu menu={menu} className={'topbar'}/>
            <Table fetchData={fetchData} table={groups}/>
        </div>
    )
}

export default MyGroups;
import { FC } from 'react'
import Item from './Item';
import { useAppSelector } from '../../hooks/redux';

const FAQAdaptive: FC = () => {
    const { faq } = useAppSelector(state=> state.FaqSlice)
    return (
        <div className='w-full max-h-full flex flex-col items-center bg-[#f0f0f0] rounded-xl overflow-auto p-2'>
            <p className='text-red-500 mb-5 text-lg font-bold'>Советы редактируются и идеализируются. Также будем добавлять новые разные темы до 01.01.2025.</p>
            {faq &&
                faq.map(item=>
                    <Item key={item._id} item={item}></Item>    
                )
            }

        </div>
    )
}

export default FAQAdaptive;
import {ECountries, EditRequest, ELessonFormatTypes, EUserLanguages, UserSchedule} from "../models/User.ts";
import {lessonFormatTypes, userCountries, userLanguages} from "../constants.ts";
import {UserRoles} from "./userRoles.ts";
import {ITranslateItemString, translations} from "./translations.tsx";
import {Elanguages} from "../store/reducers/TranslateSlice.ts";


const {
    nameText,
    sеcondNameText,
    surNameText,
    graphicText,
    parentNameText,
    actualMailText,
    nationalityText,
    languagesText,
    formatText,
    cityText,
    whatsAppNumberText,
    emailText,
    countryText,
    graphicCommentsText,
    sexText,
    birthDateText,
    maleText,
    femaleText,
    lessonDurationText
} = translations.profile

const {
    roleText,
    studentText,
    trainerText,
} = translations.access

export const fieldsTranslations = {
    name: nameText,
    email: emailText,
    country: countryText,
    comment: graphicCommentsText,
    born: birthDateText,
    sex: sexText,
    sname: sеcondNameText,
    tname: surNameText,
    shedule: graphicText,
    parentName: parentNameText,
    actualMail: actualMailText,
    nationality: nationalityText,
    languages: languagesText,
    format: formatText,
    city: cityText,
    role: roleText,
    durency: lessonDurationText,
    whatsappNumber: whatsAppNumberText,
}

const sexTypes: {
    man: ITranslateItemString
    woman: ITranslateItemString
} = {
    man: maleText,
    woman: femaleText
}

export const rolesTexts = {
    [UserRoles.STUDENT]: studentText,
    [UserRoles.TRANER]: trainerText,
}


export const getValueAndFieldText = (item: EditRequest,language: Elanguages) => {
    let value = typeof item.value === 'string' ? item.value : ""
    const name = item.field in fieldsTranslations ? fieldsTranslations[item.field as keyof typeof fieldsTranslations][language] : item.field

    switch (item.field) {
        case 'shedule': {
            const shedule = item.value as UserSchedule[]
            value = shedule.reduce((acc, cur) => {
                acc += `(${cur.time} ${cur.days.join(", ")}) `

                return acc;
            }, "")
            break;
        }
        case 'languages': {
            if (Array.isArray(item.value)) {
                value = item.value.map(lang => userLanguages[lang as EUserLanguages]?.text[language] || "").join(', ')
            }
            break;
        }
        case 'country': {
            value = userCountries?.[item.value as ECountries]?.text[language] || ""
            break;
        }
        case 'format': {
            value = lessonFormatTypes?.[item.value as ELessonFormatTypes]?.text[language] || ""
            break;
        }
        case 'born': {
            if (typeof item.value === "string") {
                value = new Date(item.value).toLocaleDateString()
            }
            break;
        }
        case 'sex': {
            if (item.value === 'man' || item.value === 'woman') {
                value = sexTypes[item.value][language]
            }
            break;
        }
        case 'role': {
            if (item.value === UserRoles.STUDENT || item.value === UserRoles.TRANER) {
                value = rolesTexts[item.value][language]
            }
            break;
        }
    }

    return {value, name}
}

import { API_URL } from '../http';

import axios from 'axios';

export async function saveTokenFCM(tokenFCM: string): Promise<void> {
  try {
    const response = await axios.post(
      API_URL + '/service1/fcm',
      JSON.stringify({
        token_fcm: tokenFCM,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error(error);
  }
}

export async function updateUserFCM(
  userId: string,
  tokenFCM: string,
): Promise<void> {
  try {
    const response = await axios.put(
      API_URL + '/service1/fcm',
      JSON.stringify({
        _id: userId,
        token_fcm: tokenFCM,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.error(error);
  }
}

import React from 'react';
import { FaUsers } from '@react-icons/all-files/fa/FaUsers';

interface SeansCardProps {
  isOnline?: boolean;
  seanser: {firstName: string; lastName: string;}|undefined;
}

const SeansCard: React.FC<SeansCardProps> = ({ isOnline,seanser }) => {
  console.log("se", seanser)

  return (
    <div className="shadow rounded-2xl bg-white p-5">
      <div className="flex flex-row items-center">
        <div>
          <FaUsers className="text-6xl mr-3" />
        </div>
        <div>
          <p className="font-medium text-2xl">20+60</p>
          <p className="font-medium text-lg">STANDARD • ТОВАРИЩЕСКАЯ</p>
        </div>
      </div>
      <div className='border-b text-base font-medium py-3' >
        <p>Дополнительное время сеансёра: 0 minutes</p>
        <p>Цвет сеансёра: Белые</p>
      </div>
      <div className="flex flex-row items-center py-3 text-lg font-medium">
        <span
          className={`w-4 h-4 rounded-full mr-2 ${
            isOnline ? 'bg-green-400' : 'bg-red-600'
          } `}
        ></span>
        {seanser?.firstName} {seanser?.lastName}
      </div>
    </div>
  );
};

export default SeansCard;

import React from 'react';
import { GiCheckMark } from '@react-icons/all-files/gi/GiCheckMark';
import { useParams } from 'react-router-dom';
import SeansService from '../../../services/SeansService';

interface StartSeansTableProps {
  leftData: {
    id: number;
    userName: string;
    rating: number;
    isOnline: boolean;
  }[];
  rightData: {
    id: number;
    userName: string;
    rating: number;
    isOnline: boolean;
  }[];
}

const acceptPlayer =  (data:any) => {
  
  // await SeansService.acceptPlayer(data);
}
const kickPlayer =  (data:any) => {
  
  // await SeansService.kickPlayer(data);
}

const StartSeansTable: React.FC<StartSeansTableProps> = ({
  leftData,
  rightData,
}) => {
  const { seansId } = useParams();

  return (
    <div className="grid grid-cols-2 justify-between p-7">
      <div className="border-r border-b-2 border-gray-300">
        {leftData.map((e) => (
          <div
            className="grid grid-cols-[16px_200px_32px] items-center text-2xl mb-5 gap-x-3"
            key={e.id}
          >
            <span
              className={`w-4 h-4 rounded-full mr-2 ${
                e.isOnline ? 'bg-green-400' : 'bg-red-600'
              } `}
            ></span>
            <span>
              {e.userName}
              <span className="font-semibold ml-2">({e.rating})</span>
            </span>
            <button
            onClick={()=>{
              acceptPlayer({seansId, studentsId: e.id})
            }}
            className="bg-gradient-blue w-8 h-8 ml-3 text-white flex items-center justify-center rounded-md">
              <GiCheckMark />
            </button>
          </div>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 pl-7">
        {rightData.map((e) => (
          <div
            className="grid grid-cols-[16px_200px_32px] items-center text-2xl mb-5 gap-x-3"
            key={e.id}
          >
            <span
              className={`w-4 h-4 rounded-full mr-2 ${
                e.isOnline ? 'bg-green-400' : 'bg-red-600'
              } `}
            ></span>
            <span>
              {e.userName}
              <span className="font-semibold ml-2">({e.rating})</span>
            </span>
            <button onClick={()=>{
              kickPlayer({seansId, studentsId: e.id})
            }} className="bg-gradient-red w-8 h-8 ml-3 text-white flex items-center justify-center rounded-md">
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StartSeansTable;

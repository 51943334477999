import * as io from 'socket.io-client';

// export const socket = io.connect('https://api.araratchess.com');
// export const API_URL = 'http://localhost:8088';
// export const socket = io.connect('https://test3.araratchess.com/');

const API_URL =
  window.location.host == 'puzzle.araratchess.com'
    ? 'https://api.araratchess.com/'
    : 'https://test3.araratchess.com/';
// export const API_URL = 'http://localhost:8088'
export const socket = io.connect(API_URL);

export const SERVER_URI = 'https://apijitsi.araratchess.com/';
// export const SERVER_URI = 'http://localhost:4000/';

import { MutableRefObject, useEffect } from 'react';

interface useEndScrollProps {
    rootRef?: MutableRefObject<HTMLDivElement | null>;
    targetRef: MutableRefObject<HTMLDivElement | null>;
    onIntersect?: () => void;
}

export const useEndScroll =  ({ rootRef, targetRef, onIntersect } : useEndScrollProps) => {
    useEffect(() => {
        let observer: IntersectionObserver;
        const rootElement = rootRef?.current as Element || null;
        const targetElement = targetRef.current as Element;
        if (onIntersect) {
            const options = {
                root: rootElement,
                rootMargin: '0px',
                threshold: 1.0,
            };
            
            observer = new IntersectionObserver(([entity]) => {
                if (entity.isIntersecting) {
                    onIntersect();
                }
            }, options);
            observer.observe(targetElement);
            return () => {
                if (observer && targetElement) {
                    observer.unobserve(targetElement);
                }
            };
        }
    }, [onIntersect, rootRef, targetRef]);
};
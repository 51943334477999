import { Link, useParams } from 'react-router-dom';

const SessionGamesPage = () => {
  const { groupId } = useParams();

  return (
    <div className="p-3">
      <div className="flex flex-row min-h-screen shadow-md bg-gray-100 rounded-md">
        <div>
          <h2 className="text-blue-700 text-4xl font-semibold">
            Название сеанса
          </h2>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-8 text-2xl font-semibold">
            <div className="">
              <p className="text-center">0</p>
              <p>ИГРАЮТСЯ</p>
            </div>
            <div className="text-green-700">
              <p className="text-center">0</p>
              <p>ПОБЕД</p>
            </div>
            <div className="text-yellow-500">
              <p className="text-center">0</p>
              <p>НИЧЬИХ</p>
            </div>
            <div className="text-red-600">
              <p className="text-center">0</p>
              <p>ПОРАЖЕНИЙ</p>
            </div>
          </div>
          <div>
            <Link
              to={`/session/${groupId}/table`}
              className="mt-5 bg-gradient-red text-2xl font-semibold rounded-full py-4 px-12 hover:bg-gradient-red flex items-center hover:text-white"
            >
              Результаты лиги
            </Link>
          </div>
        </div>
        <div>chess-boards</div>
      </div>
    </div>
  );
};

export default SessionGamesPage;

import {ITranslateItemString} from "./translations.tsx";
import {ELessonFormatTypes} from "../models/User.ts";
import {ISelect} from "../models/ISelect.ts";
import {Elanguages} from "../store/reducers/TranslateSlice.ts";

export const createSelectOptionsFromObj = (obj: {
    [key in string]: {
        text: ITranslateItemString,
        slug: ELessonFormatTypes
    }
},language: Elanguages) => {
    return Object.values(obj).map((item, index) => ({
        id: index.toString(),
        name: item.text[language],
        slug: item.slug
    })) as ISelect[]
}
import { FC } from 'react'

interface TitleProps {
    name: string;
    isPassive?: boolean,
    className?: string
}

const Title: FC<TitleProps> = ({name,isPassive,className}) => {
    return (
        <div className={`${isPassive ? 'bg-gradient-button' : 'bg-gradient-title'}    text-lg 2xl:text-xl flex justify-center items-center text-[#6D5733] rounded-full font-bold text-center  ${className || ""}`}>{name}</div>
    )
}

export default Title;
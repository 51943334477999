import  {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {User} from '../../models/User';
import PermissionsModal from '../Modals/PermissionsModal';
import RemoveUserModal from '../Modals/RemoveUserModal';
import {ITranslateItemString, translations} from "../../utils/translations.tsx";
import EditRequestsModal from "../Modals/EditRequestsModal.tsx";
import {IUserData} from "../../models/IUserData.ts";
import {UserRoles} from "../../utils/userRoles.ts";
import classNames from 'classnames';
import {getRequisites} from "../../store/reducers/BalansSlice.ts";
import RequisiteRequestModal from "../Modals/RequisiteRequestModal.tsx";

interface TableProps {
    table: UserwithData[];
    onEditData: (user: User) => void
    onArchive: (id: string) => void,
    onAcceptUserEdits: (id: string, data: { [key in string]: boolean }) =>  Promise<undefined | User>,
}


type UserwithData = IUserData & User

const filterUserData = (item: UserwithData) => ({
    _id: item._id,
    name: item.name,
    sname: item.sname,
    tname: item.tname,
    role: item.role,
    email: item.email,
    requizits: item.requizits,
    archive: item.archive,
    seance: item.seance,
    online: item.online,
    offline: item.offline,
    allgroups: item.allgroups,
})


const {
    toArchiveText,
    unArchiveText,
    profileText,
    directorText,
    zDirectorText,
    adminText,
    trainerText,
    newUserText,
    studentText,
    trainerMedhodistText,
    programmerText,
    editText,
    newTrainerText,
    newStudentText,
    rejectedRequestsText,
    editRequestsText,
    requisiteRequestsText,
} = translations.access


const roles: {
    [key in UserRoles]: ITranslateItemString
} = {
    [UserRoles.NEWUSER]: newUserText,
    [UserRoles.DIRECTOR]: directorText,
    [UserRoles.ZDIRECTOR]: zDirectorText,
    [UserRoles.ADMIN]: adminText,
    [UserRoles.TRANER]: trainerText,
    [UserRoles.STUDENT]: studentText,
    [UserRoles.TRANERMETODIST]: trainerMedhodistText,
    [UserRoles.PROGRAMMER]: programmerText,
}

const newUserRoles: {
    [UserRoles.TRANER]: ITranslateItemString
    [UserRoles.STUDENT]: ITranslateItemString
} = {
    [UserRoles.TRANER]: newTrainerText,
    [UserRoles.STUDENT]: newStudentText,
}


const {
    groupsText
}: {
    groupsText: ITranslateItemString
} = translations.profile

const Table: FC<TableProps> = ({
                                   table,
                                   onEditData,
                                   onArchive,
                                   onAcceptUserEdits,
                               }) => {
    const dispatch = useAppDispatch()
    const language = useAppSelector(state => state.TranslateSlice.language)
    const requisites = useAppSelector(state => state.BalanceSlice.requisites)

    const [modal, setModal] = useState<boolean>(false);
    const [modal2, setModal2] = useState<boolean>(false);
    const [requisiteRequestsOpenedId, setRequisiteRequestsOpenedId] = useState<string>("");
    const [editRequestsModalId, setEditRequestsModalId] = useState<string>("");
    const [onlyRejected, setOnlyRejected] = useState<boolean>(false);
    const {user} = useAppSelector((state) => state.UserSlice);
    const [userdata, setUserdata] = useState<IUserData>({
        _id: '',
        name: '',
        sname: '',
        tname: '',
        role: UserRoles.NEWUSER,
        email: '',
        country: '',
        archive: false,
        parentName: "",
        actualMail: "",
        nationality: "",
        city: "",
        whatsappNumber: "",
    });

    useEffect(() => {
        if (!requisites.length) dispatch(getRequisites())
    }, []);

    const editHandler = (user: UserwithData) => {
        setUserdata(user);
        setModal(true);
    };
    const removeHandler = (user: IUserData) => {
        setUserdata(user);
        setModal2(true);
    };


    const openEditRequests = (id: string, onlyRejected?: boolean) => {
        setOnlyRejected(!!(onlyRejected))
        setEditRequestsModalId(id)
    }

    const openRequisitesRequests = (id: string) => setRequisiteRequestsOpenedId(id)
    const closeRequisitesRequests = () => setRequisiteRequestsOpenedId("")

    const closeEditRequests = () => setEditRequestsModalId('')

    const curEditRequestUser = editRequestsModalId && table.find(item => item._id === editRequestsModalId) || null
    const curRequisiteRequestUser = requisiteRequestsOpenedId && table.find(item => item._id === requisiteRequestsOpenedId) || null

    const curRequisiteRequestId = curRequisiteRequestUser?.editRequest ?
        curRequisiteRequestUser?.editRequest?.find(item => item.field === "requizits").value
        : null

    const curRequisiteRequest = requisites.find(item => item.requisiteID === curRequisiteRequestId as number | null)
    const handleAcceptEdits = (id: string, data: { [key in string]: boolean }, newRole?: UserRoles) => {
        onAcceptUserEdits(id, data)
            .then((curUser) => {
            if (newRole && curUser && curEditRequestUser && curEditRequestUser.role === UserRoles.NEWUSER) editHandler({
                ...curUser,
                role: newRole
            })
        })
    }

    const handleEditData = (user: User) => {
        onEditData(user)
    }

    return (
        <>
            <div
                className="pb-0 px-2 xl:pb-5 p-5 h-full lg:h-[calc(100vh-217px)] xl:h-[calc(100vh-110px)] lg:overflow-auto">
                {
                    table.map(item => {
                        let roleText = roles?.[item.role]?.[language] || ""
                        let isTrainer = item.role === UserRoles.TRANER
                        const isAdmin = item.role === UserRoles.ADMIN


                        if (item.role === UserRoles.NEWUSER && item?.editRequest?.length) {
                            const reqRole = item.editRequest.find(item => item.field === 'role')?.value
                            if (reqRole && reqRole === UserRoles.STUDENT || reqRole === UserRoles.TRANER) {
                                isTrainer = reqRole === UserRoles.TRANER
                                roleText = newUserRoles[reqRole][language]
                            }
                        }
                        let roleColor = 'red'
                        if (isTrainer) roleColor = 'blue'
                        if (isAdmin) roleColor = 'green'

                        const mainEditRequests = item.editRequest && item.editRequest.filter(item => item.field !== 'requizits')
                        const requisiteEditRequests = item.editRequest && item.editRequest.filter(item => item.field === 'requizits' && !item.rejectedAt && !item.acceptedAt).length


                        const rejectedrequestsCount = mainEditRequests
                            ? mainEditRequests.filter(item => item.rejectedAt).length
                            : 0

                        const openRequestsCount = mainEditRequests
                            ? mainEditRequests.filter(item => !item.rejectedAt && !item.acceptedAt).length
                            : 0

                        return (
                            <div key={item._id}>
                                {
                                    ((item.role === 'DIRECTOR' || user.role === 'ZDIRECTOR' || item.role === 'ADMIN' || item.role
                                        === 'TRANER') && user.role === 'ADMIN') ?
                                        null
                                        :
                                        <div
                                            className={classNames(
                                                "w-full rounded-xl p-3 flex  flex-col lg:flex-row gap-3 lg:gap-0 justify-between mb-2 lg:mb-5",
                                                {
                                                    'bg-[#f87168]': item.archive,
                                                    'bg-gradient-top-menu': !item.archive
                                                }
                                            )}
                                        >
                                            <div className="flex flex-col sm:flex-row items-center w-full">
                                                <div className="w-12 mr-5"><img className='w-full' src={item.avatar}
                                                                                alt="avatar"/>
                                                </div>
                                                <div className="flex flex-col sm:basis-[350px]">
                                                    <p className='text-base sm:text-xl text-white'>{item.name} {item.sname} {item.tname}
                                                        <span
                                                            className={`ml-1 text-sm sm:text-base bg-white px-2 shadow-lg rounded-full text-${roleColor}-500 whitespace-nowrap`}>{roleText}</span>
                                                    </p>
                                                    <p className='text-xl text-white'>{item.email}</p>
                                                </div>
                                                <div className={'flex flex-col gap-[8px]'}>
                                                    {
                                                        !!(requisiteEditRequests) &&
                                                        <button
                                                            onClick={() => openRequisitesRequests(item._id)}
                                                            className={'bg-gradient-button whitespace-nowrap relative px-[6px] py-[4px] rounded-full flex justify-between items-center gap-[6px] disabled:cursor-default'}
                                                        >
                                                            <span>{requisiteRequestsText[language]}</span>
                                                            <span
                                                                className={`text-white font-bold w-[24px] h-[24px] rounded-full bg-blue-600 flex justify-center items-center`}>{requisiteEditRequests}</span>
                                                        </button>
                                                    }
                                                    {
                                                        !!(rejectedrequestsCount) &&
                                                        <button
                                                            onClick={() => openEditRequests(item._id, true)}
                                                            className={'bg-gradient-button whitespace-nowrap relative px-[6px] py-[4px] rounded-full flex justify-between items-center gap-[6px] disabled:cursor-default'}
                                                        >
                                                            <span>{rejectedRequestsText[language]}</span>
                                                            <span
                                                                className={`text-white font-bold w-[24px] h-[24px] rounded-full bg-green-600 flex justify-center items-center`}>{rejectedrequestsCount}</span>
                                                        </button>
                                                    }
                                                    {
                                                        !!(openRequestsCount) &&
                                                        <button
                                                            onClick={() => openEditRequests(item._id)}
                                                            className={'bg-gradient-button whitespace-nowrap relative px-[6px] py-[4px] rounded-full flex justify-between items-center gap-[6px] disabled:cursor-default'}
                                                        >
                                                            <span>{editRequestsText[language]}</span>
                                                            <span
                                                                className={`text-white font-bold w-[24px] h-[24px] rounded-full bg-red-600 flex justify-center items-center`}>{openRequestsCount}</span>
                                                        </button>
                                                    }
                                                </div>
                                                <div className='text-white ml-5'>
                                                    <p className='font-medium text-lg'>{groupsText[language]}:</p>
                                                    {item.groups?.map((group, index) => {
                                                        index++;
                                                        if (index <= 3) {
                                                            return (
                                                                <p className="text-lg">{group.name}</p>
                                                            )
                                                        }
                                                    })}
                                                    {(item?.groups?.length || 0) > 3 &&
                                                        <p>Полный список груп в профиле ...</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-col sm:basis-[350px]">
                                                <p className='text-base sm:text-xl text-white'>
                                                    {item.removedFromGroupBy?.name}
                                                </p>
                                            </div>
                                            <div
                                                className="flex gap-3 sm:gap-0 flex-wrap sm:flex-nowrap justify-between items-center">
                                                <button
                                                    disabled={!!(openRequestsCount || item.role === UserRoles.NEWUSER)}
                                                    className=' w-[47%] sm:w-auto bg-gradient-button text-lg font-semibold rounded-full py-0.5 sm:py-2 px-1 sm:px-5 sm:mr-4'
                                                    onClick={() => editHandler(item)}>{editText[language]}</button>
                                                <Link
                                                    className=' w-[47%] text-center sm:w-auto bg-gradient-button text-lg font-semibold rounded-full py-0.5 sm:py-2 px-1 sm:px-5 sm:mr-4'
                                                    to={'/profile/' + item._id}>{profileText[language]}</Link>
                                                <button
                                                    className=' w-[47%] sm:w-auto bg-gradient-button text-lg font-semibold rounded-full py-0.5 sm:py-2 px-1 sm:px-5'
                                                    onClick={() => removeHandler(filterUserData(item))}>{item.archive ? unArchiveText[language] : toArchiveText[language]}</button>
                                            </div>
                                        </div>
                                }
                            </div>
                        )
                    })}
            </div>
            <PermissionsModal
                modal={modal}
                setModal={setModal}
                userdata={userdata}
                onEditData={handleEditData}
                openEditRequests={openEditRequests}
            />
            <RemoveUserModal
                modal={modal2}
                setModal={setModal2}
                email={userdata.email}
                _id={userdata._id}
                archive={!!(userdata.archive)}
                onArchive={onArchive}
            />
            <EditRequestsModal
                modalOpenedId={editRequestsModalId}
                closeEditRequests={closeEditRequests}
                editRequests={curEditRequestUser?.editRequest || []}
                onAcceptUserEdits={handleAcceptEdits}
                onlyRejected={onlyRejected}
                openEditRequests={openEditRequests}
            />
            <RequisiteRequestModal
                requisiteRequestsOpenedId={requisiteRequestsOpenedId}
                closeRequisitesRequests={closeRequisitesRequests}
                curRequisiteRequest={curRequisiteRequest}
                onAcceptUserEdits={onAcceptUserEdits}
            />
        </>
    )
}

export default Table;
// import React from 'react';
import male from '../../../assets/icons/male.png';
import female from '../../../assets/icons/female.png';

const dates = [
  '01/12',
  // '01/11',
  // '01/10',
  // '01/09',
  // '01/08',
  // '01/07',
  // '01/06',
  // '01/05',
  // '01/04',
  // '01/03',
  // '01/02',
  // '01/01',
];

const SeansTable = ({ data }: any) => {
  return (
    <div>
      <table className="w-full bg-[#f0f0f0] rounded-xl border-collapse ">
        {/* <caption className="text-4xl font-semibold py-8">
          Сеанс одновременной игры
        </caption> */}
        <thead className="bg-gray-300">
          <tr>
            <td className="text-center font-semibold text-3xl py-1">№</td>
            <td className="text-center font-semibold text-3xl py-1">ФИО</td>
            <td className="text-center font-semibold text-3xl py-1">Тренер</td>
            <td className="text-center font-semibold text-3xl py-1">Группа</td>
            <td className="text-center font-semibold text-3xl py-1">
              Дата рож.
            </td>
            <td className="text-center font-semibold text-3xl py-1">Пол</td>
            <td className="text-center font-semibold text-3xl py-1">Рейтинг</td>
            <td className="text-center font-semibold text-3xl py-1">Очки</td>
            {dates.map((e: any) => (
              <td className="text-center font-semibold text-3xl py-1">{e}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item: any) => (
              <tr key={item._id} className="border-b-2 border-b-[#C4C4C4]">
                <td className="text-center p-1">{item.numberPlace}</td>
                <td className="p-1">{item.fullName}</td>
                <td className="p-1">
                  <div className="flex flex-row text-3xl">
                    <span>{item.tranerName}</span>
                    <span>{item.tranerMonthes}</span>
                  </div>
                </td>
                <td className="p-1">
                  <div className="flex flex-row text-3xl">
                    <span>{item.group}</span>
                    <span>{item.groupMonthes}</span>
                  </div>
                </td>
                <td className="p-1">{item.birthDate}</td>
                <td className="p-1">
                  {item.gender ? (
                    <img src={male} alt="male" />
                  ) : (
                    <img src={female} alt="female" />
                  )}
                </td>
                <td className="p-1">{item.rating}</td>
                <td className="p-1">{item.points}</td>
                {item.marks?.map((e: any) => (
                  <td
                    className={`p-1 text-xl font-medium ${
                      e == 3
                        ? 'text-[#1EA413]'
                        : e == 2
                        ? 'text-[#E7C173]'
                        : 'text-[#FF0000]'
                    }`}
                  >
                    {e}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SeansTable;

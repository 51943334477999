// Цвет сеансёра в каждой партии
export const seanserColors = [
  {
    id: '1',
    name: 'Белый',
    slug: 'white',
  },
  {
    id: '2',
    name: 'Рандом',
    slug: 'random',
  },
  {
    id: '3',
    name: 'Чёрный',
    slug: 'black',
  },
];

// Начальное время на часах
export const startTime = [
  {
    id: '1',
    name: '5 минут',
    slug: '5min',
  },
  {
    id: '2',
    name: '10 минут',
    slug: '10min',
  },
  {
    id: '3',
    name: '15 минут',
    slug: '15min',
  },
  {
    id: '4',
    name: '20 минут',
    slug: '20min',
  },
  {
    id: '5',
    name: '30 минут',
    slug: '30min',
  },
  {
    id: '6',
    name: '40 минут',
    slug: '40min',
  },
  {
    id: '7',
    name: '50 минут',
    slug: '50min',
  },
  {
    id: '8',
    name: '60 минут',
    slug: '60min',
  },
  {
    id: '9',
    name: '70 минут',
    slug: '70min',
  },
  {
    id: '10',
    name: '80 минут',
    slug: '80min',
  },
  {
    id: '11',
    name: '90 минут',
    slug: '90min',
  },
  {
    id: '12',
    name: '120 минут',
    slug: '120min',
  },
  {
    id: '13',
    name: '140 минут',
    slug: '140min',
  },
  {
    id: '14',
    name: '160 минут',
    slug: '160min',
  },
  {
    id: '15',
    name: '180 минут',
    slug: '180min',
  },
];

// Добавка времени
export const additionalTime = [
  {
    id: '1',
    name: '0 секунд',
    slug: '0s',
  },
  {
    id: '2',
    name: '1 секунд',
    slug: '1s',
  },
  {
    id: '3',
    name: '2 секунд',
    slug: '2s',
  },
  {
    id: '4',
    name: '3 секунд',
    slug: '3s',
  },
  {
    id: '5',
    name: '4 секунд',
    slug: '4s',
  },
  {
    id: '6',
    name: '5 секунд',
    slug: '5s',
  },
  {
    id: '7',
    name: '6 секунд',
    slug: '6s',
  },
  {
    id: '8',
    name: '7 секунд',
    slug: '7s',
  },
  {
    id: '9',
    name: '10 секунд',
    slug: '10s',
  },
  {
    id: '10',
    name: '15 секунд',
    slug: '15s',
  },
  {
    id: '11',
    name: '20 секунд',
    slug: '20s',
  },
  {
    id: '12',
    name: '25 секунд',
    slug: '25s',
  },
  {
    id: '13',
    name: '30 секунд',
    slug: '30s',
  },
  {
    id: '14',
    name: '40 секунд',
    slug: '40s',
  },
  {
    id: '15',
    name: '50 секунд',
    slug: '50s',
  },
  {
    id: '16',
    name: '60 секунд',
    slug: '60s',
  },
  {
    id: '17',
    name: '90 секунд',
    slug: '90s',
  },
  {
    id: '18',
    name: '120 секунд',
    slug: '120s',
  },
  {
    id: '19',
    name: '150 секунд',
    slug: '150s',
  },
  {
    id: '20',
    name: '180 секунд',
    slug: '180s',
  },
];

// Дополнительное время сеансёра
export const extraTime = [
  {
    id: '1',
    name: '-120 минут',
    slug: '-120min',
  },
  {
    id: '2',
    name: '-90 минут',
    slug: '-90min',
  },
  {
    id: '3',
    name: '-60 минут',
    slug: '-60min',
  },
  {
    id: '4',
    name: '-50 минут',
    slug: '-50min',
  },
  {
    id: '5',
    name: '-40 минут',
    slug: '-40min',
  },
  {
    id: '6',
    name: '-30 минут',
    slug: '-30min',
  },
  {
    id: '7',
    name: '-20 минут',
    slug: '-20min',
  },
  {
    id: '8',
    name: '-15 минут',
    slug: '-15min',
  },
  {
    id: '9',
    name: '-10 минут',
    slug: '-10min',
  },
  {
    id: '10',
    name: '-5 минут',
    slug: '-5min',
  },
  {
    id: '11',
    name: '0 минут',
    slug: '0min',
  },
  {
    id: '12',
    name: '5 минут',
    slug: '5min',
  },
  {
    id: '13',
    name: '10 минут',
    slug: '10min',
  },
  {
    id: '14',
    name: '15 минут',
    slug: '15min',
  },
  {
    id: '15',
    name: '20 минут',
    slug: '20min',
  },
  {
    id: '16',
    name: '30 минут',
    slug: '30min',
  },
  {
    id: '17',
    name: '40 минут',
    slug: '40min',
  },
  {
    id: '18',
    name: '50 минут',
    slug: '50min',
  },
  {
    id: '19',
    name: '60 минут',
    slug: '60min',
  },
  {
    id: '20',
    name: '90 минут',
    slug: '90min',
  },
];

// Добавка времени сеансёра для каждого игрока
export const additionalTimeSeanserForPlayers = [
  {
    id: '1',
    name: '0 секунд',
    slug: '0s',
  },
  {
    id: '2',
    name: '10 секунд',
    slug: '10s',
  },
  {
    id: '3',
    name: '20 секунд',
    slug: '20s',
  },
  {
    id: '4',
    name: '30 секунд',
    slug: '30s',
  },
  {
    id: '5',
    name: '40 секунд',
    slug: '40s',
  },
  {
    id: '6',
    name: '50 секунд',
    slug: '50s',
  },
  {
    id: '7',
    name: '60 секунд',
    slug: '60s',
  },
  {
    id: '8',
    name: '90 секунд',
    slug: '90s',
  },
  {
    id: '9',
    name: '120 секунд',
    slug: '120s',
  },
  {
    id: '10',
    name: '180 секунд',
    slug: '180s',
  },
  {
    id: '11',
    name: '240 секунд',
    slug: '240s',
  },
  {
    id: '12',
    name: '300 секунд',
    slug: '300s',
  },
];

// Время до начала сеанса
export const timeBeforeStart = [
  {
    id: '1',
    name: '2 минут',
    slug: '2min',
  },
  {
    id: '2',
    name: '5 минут',
    slug: '5min',
  },
  {
    id: '3',
    name: '10 минут',
    slug: '10min',
  },
  {
    id: '4',
    name: '15 минут',
    slug: '15min',
  },
  {
    id: '5',
    name: '20 минут',
    slug: '20min',
  },
  {
    id: '6',
    name: '25 минут',
    slug: '25min',
  },
  {
    id: '7',
    name: '30 минут',
    slug: '30min',
  },
];

import React, { FC, useEffect, Suspense } from 'react'
import Chats from '../../components/Messenger/Chats/Chats';
const Chat =  React.lazy(() => import('../../components/Messenger/Chat/Chat')) ;
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getChat } from '../../store/reducers/MessengerSlice';
import { useParams } from "react-router-dom";
import { socket } from '../../sockets/socket';
import { ChatRoomSocket, ChatRoomDisconnectSocket } from '../../sockets/MessengerSockets';
import { setReplyMessage, setEditMessage, setMessagesPage } from '../../store/reducers/MessengerSlice';
import { ServerError } from '../../models/response/ServerError';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/UI/Loader';
import { useDebounce } from '../../hooks/useDebounce';

const ChatPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userid } = useParams();
    const { isChatLoading, hasMoreMessages, messagesPage } = useAppSelector(state => state.MessengerSlice);
    
    const getDialog = async () => {
        if(userid) {
            dispatch(setMessagesPage(1));
            const response = await dispatch(getChat(userid));
            const err = response.payload as ServerError
            if(err.error) {
                navigate('/messenger');
            }
        }
    };

    const onEndScroll = useDebounce(async () => {
        if (hasMoreMessages && !isChatLoading) {
            dispatch(setMessagesPage(messagesPage + 1));
            if (userid) {
                await dispatch(getChat(userid));
            }
        }
    }, 1000);

    useEffect(() => {
        void getDialog();
        return () => {
            if(userid) {
                ChatRoomDisconnectSocket(userid);
            }
            dispatch(setReplyMessage(null));
            dispatch(setEditMessage(null));
        }
    }, [userid]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if(userid) {
            ChatRoomSocket(userid);
        }
    }, [userid, socket])  // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <>
            <div className='hidden sm:block'>
                <Chats/>
            </div>
            <Suspense fallback={<Loader />}>
                <Chat onEndScroll={onEndScroll}/>
            </Suspense>
        </>
    )
}

export default ChatPage;
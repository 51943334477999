import $api, { $api2 } from "../http";
import axios, { AxiosResponse } from 'axios';
import { AuthResponse } from "../models/response/AuthResponse";

export default class SeansService {
    static async getAllSeans(): Promise<AxiosResponse<AuthResponse>> {
        return $api2.get<AuthResponse>('/seans')
    }
    static async getOneSeans(seansId:string|undefined): Promise<AxiosResponse<AuthResponse>> {
        return $api2.get<AuthResponse>('/seans/one', {params: {seansId}})
    }
    static async createSeans(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.post<AuthResponse>('/seans', data)
    }
    static async studentJoin(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.put<AuthResponse>('/seans/student-join', data)
    }
    static async acceptPlayer(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.put<AuthResponse>('/seans/accept-player', data)
    }
    static async kickPlayer(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.put<AuthResponse>('/seans/kick-player', data)
    }
    static async leaveTheSeans(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.put<AuthResponse>('/seans/leave', data)
    }
    static async startSeans(data:any): Promise<AxiosResponse<AuthResponse>> {
        return $api2.put<AuthResponse>('/seans/start', data)
    }

    

    // static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.post<AuthResponse>('/service1/auth/login', {email, password})
    // }

    // static async login_lichess(): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.post<AuthResponse>('/service1/auth/login_lichess')
    // }
    
    // static async getUsers(): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.get('/service1/auth/users')
    // }
    
    // static async editlevel(data): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.put('/service1/auth/level', data)
    // }

    // static async logout(): Promise<void> {
    //     return $api.post('/service1/auth/logout')
    // }

    // static async editUser(data: EditUserReQData): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.put<AuthResponse>('/service1/auth/user', data)
    // }
    // static async setRequizits(type: string): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.post<AuthResponse>('/service1/auth/setrequizits', {type: type})
    // }
    // static async deleteAcceptedRequests(id: string,isRequisite?: boolean): Promise<AxiosResponse<AuthResponse>> {
    //     return $api.put<AuthResponse>(`/service1/auth/user/accept/${id}${isRequisite ? '?requisite=true' : ""}`, {})
    // }
    // static async JitsiJWT(avatar: string, email: string, name: string, id: string, moderator: boolean): Promise<AxiosResponse<string>> {
    //     const perm = [];
    //     if(moderator) {
    //         perm.push("RECORDING");
    //     }
    //     const CurrentTime = new Date();
    //     CurrentTime.setMinutes(CurrentTime.getMinutes() + 120);
    //     return axios.post<string>('https://ararat-47375650f6ef.herokuapp.com/token-generator/token', 
    //     {
    //         avatar: avatar,
    //         email: email,
    //         expTimestampSec: Math.round(CurrentTime / 1000),
    //         id: id,
    //         moderator: moderator,
    //         name: name,
    //         permissions: [
    //             'RECORDING'
    //         ],
    //         roomName: '*'
    //     })
    // }
}
import React, { useState } from 'react'
import SeansCard from '../../../components/UI/cards/SeansCard'
import Chat from '../../../components/TestLesson/Chat/Chat'
import Modal from '../../../components/UI/Modal';
// import ChessBoard from '../../../components/TestLesson/ChessBoard/ChessBoardNew';
import { IMove } from '../../../models/MyGroups/IMove';
// import ChessBoard from '../../../components/OnlineLesson/ChessBoard/ChessBoard';
import { IMaterial } from '../../../models/Program/IMaterial';
import { Chess } from 'chess.js';
import ChessBoard from '../../../components/TestLesson/ChessBoard/ChessBoard';
import { Chessboard } from 'react-chessboard';
import Switch from '../../../components/UI/Switch';
import GameCard from '../../../components/UI/cards/GameCard';
// import ChessBoard from '../../../components/Homework/ChessBoard';

let isStudent = false;

const SessionGamePage = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [moves, setMoves] = useState<IMove[]>([]);
  const [game, setGame] = useState(new Chess());
  const [material, setMaterial] = useState<IMaterial>();
  const [globalMode, setGlobalMode] = useState<boolean>(false);
  const [clear, setClear] = useState<boolean>(false);
  const [moveMode, setmoveMode] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const PrevBackTheme = (bool: boolean) => {
    // const index = program.findIndex((item) => item._id === material?._id);
    // if (groupId) {
    //   if (bool) {
    //     if (program[index + 1]) {
    //       setMaterial(program[index + 1]);
    //       GroupChangeMaterialSocket({
    //         room: groupId,
    //         material: program[index + 1],
    //       });
    //     }
    //   } else {
    //     if (program[index - 1]) {
    //       setMaterial(program[index - 1]);
    //       GroupChangeMaterialSocket({
    //         room: groupId,
    //         material: program[index - 1],
    //       });
    //     }
    //   }
    // }
  };

  return (
    <div className='p-3' >
      <div className="flex flex-row min-h-screen shadow-md bg-gray-100 rounded-md">
        <div>
          <SeansCard />
          <div className={`${isStudent ? 'desk-block' : ''} flex-1 overflow-auto`}>
            <Chat />
          </div>
          <button onClick={()=>setModal(true)} className="bg-gradient-button text-red-600 underline text-2xl font-semibold rounded-full py-4 px-12 hover:bg-gradient-appricot text-center">
            Как вести сеанс
          </button>
        </div>
        <div className='w-[75vmin]' >
            <div className='bg-white px-5 py-2 rounded-xl' >

            <div id="board_wrapper">
                <canvas id="primary_canvas" 
                // className='w-[calc(100vh-281px)] h-[calc(100vh-281px)] max-2xl:w-[calc(100vh-200px)] max-2xl:h-[calc(100vh-200px)]'
                className='w-[75vmin]'
                ></canvas>
                <canvas id="drawing_canvas" 
                className='w-[75vmin]'
                // className='w-[calc(100vh-281px)] h-[calc(100vh-281px)] max-2xl:w-[calc(100vh-200px)] max-2xl:h-[calc(100vh-200px)]'
                ></canvas>
                <Chessboard
                    animationDuration={200}
                    // arePiecesDraggable={moveMode || user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR' || user.role === 'TRANER' ? true : false}
                    position={game.fen()}
                    // onSquareClick={onSquareClick}
                    // onSquareRightClick={onSquareRightClick}
                    // onPromotionPieceSelect={onPromotionPieceSelect}
                    // boardOrientation={boardOrientation}
                    // onDragOverSquare={onDragOverSquare}
                    areArrowsAllowed={false}
                    //promotionDialogVariant='vertical'
                    customArrowColor='rgba(0,0,0,0)'
                    // customSquareStyles={{
                    // ...moveSquares,
                    // ...optionSquares,
                    // }}
                    // onPieceDragBegin={onPieceDragBegin}
                    // onPieceDragEnd={onPieceDragEnd}
                    // promotionToSquare={moveTo}
                    // showPromotionDialog={showPromotionDialog}
                    // onPieceDrop={onDrop}
                    // customPieces={type === 'customtask' && {
                    //     "bP": (props) => CustomPieceRenderer(props)
                    // }}
                    
                    />
            </div>
            
            <div className='flex font-semibold justify-end mt-3' >
              <div>
                <span className='p-1' >
                  1
                </span>
                <span className='p-1' >
                  1
                </span>
                <span className='p-1' >
                  1
                </span>
                <span className='p-1' >
                  1
                </span>
              </div>
              <div className='flex justify-between flex-row' >
                <p className='font-normal px-2' >Hrachya</p>
                <p>3</p>
              </div>
            </div>
            <div className='flex font-semibold justify-end' >
              <div>
                <span className='p-1' >
                  0
                </span>
                <span className='p-1' >
                  0
                </span>
                <span className='p-1' >
                  0
                </span>
                <span className='p-1' >
                  0
                </span>
              </div>
              <div className='flex justify-between flex-row' >
                <p className='font-normal px-2' >araratch</p>
                <p>0</p>
              </div>
            </div>
                    </div>
        </div>
        <div>

          <GameCard/>
          <div className='mt-5' >
            <Switch 
            onChange={setChecked}
            value={checked}
            />
          </div>

        </div>
      </div>


      <Modal
        noclosable={false}
        active={modal}
        className="!max-w-full rounded-xl"
        setActive={setModal}
      >
        <div className='text-2xl' >
          <p className='mb-5' >
            Что такое Сеанс? - Сеанс одновременной игры — форма спортивного
            мероприятия, в котором один человек (сеансёр) одновременно играет в
            интеллектуальную игру (шахматы, шашки, го) с несколькими
            противниками.
          </p>
          <p className='mb-5' >
            ДЛЯ ТРЕНЕРА ИНСТРУКЦИЯ КАК СОЗДАТЬ СЕАНС в самом низу этого
            документа!
          </p>
          <p>(прочитать с детьми ДО СЕАНСА)</p>
          <p className='mb-5' >Правила поведения во время игры</p>
          <p>Молчание – золото</p>
          <p className='mb-5' >
            В шахматы играют молча. Для того чтобы подать голос, нужны веские
            основания.
          </p>
          <p className='mb-5' >
            Необходимо соблюдать тишину. В первую очередь за доской.
            Категорически недопустимо мешать думать: комментировать ходы
            соперника, подсказки другим игрокам.
          </p>
          <p>Отпустил руку - ход сделан!</p>
          <p className='mb-5' >
            Если сделал ход фигурой и отпустил руку - ход сделан и назад не
            возвращается.
          </p>
          <p>Правила во время проведения сеанса </p>
          <p className='mb-5' >
            1. Ученик, который проиграл и у него на часах больше 10 минут,
            лишается права участвовать на следующем Сеансе, но может прийти и
            посмотреть (не играть) сеанс в качестве гостья.
          </p>
          <p>2. Ученик, который БЕСПЛАТНО ОТДАЛ фигуру, делает приседания.</p>
          <ul>
            <li>пешка - 5 приседаний</li>
            <li>конь, слон - 10 приседаний</li>
            <li>ладья - 15 приседаний</li>
            <li>ферзь - 20 приседаний</li>
            <li>
              не увидел, что грозит мат с одного хода или пропустил шанс
              поставить мат с одного хода - 25 приседаний
            </li>
          </ul>
          <span className='my-5' >
            ........................................................................................................................................................................................................................................................................................
          </span>
        </div>
      </Modal>
    </div>
  )
}

export default SessionGamePage
import $api from "../http";
import {AxiosResponse} from 'axios';
import {IgetGroup, IgetGroups, IRemoveUserFromGroup, IgetLogs, IgetRecs} from "../models/response/GroupResponses";
import {IGroupEdit} from "../models/response/IGroup";

export default class GroupService {
    static async getGroups(archive?: boolean, search?: string, videocounter?: boolean, noStudents?: boolean): Promise<AxiosResponse<IgetGroups>> {
        const payload = {} as { archive?: boolean, search?: string, videocounter?: boolean, noStudents?: boolean }
        if (archive === true) {
            payload.archive = true;
        } else if (archive === false) {
            payload.archive = false;
        }
        if (search) {
            payload.search = search;
        }
        if (videocounter === true) {
            payload.videocounter = true;
        } else if (videocounter === false) {
            payload.videocounter = false;
        }

        if (noStudents === true) {
            payload.noStudents = true;
        } else if (noStudents === false) {
            payload.noStudents = false;
        }
        return $api.get<IgetGroups>('/service1/groups', {params: payload})
    }

    static async getGroup(groupId: string): Promise<AxiosResponse<IgetGroup>> {
        return $api.get<IgetGroup>('/service1/groups/' + groupId);
    }

    static async createGroup(
        name: string,
        traners: Array<string>,
        level: string,
        starts: string,
        dates?: [
            {
                days: [number],
                time: string
            }
        ],
        country: string
    ): Promise<AxiosResponse<IgetGroup>> {
        return $api.post<IgetGroup>('/service1/groups', {
            name,
            traners,
            level,
            starts,
            dates,
            country
        }, {withCredentials: true})
    }

    static async editGroup(groupId: string, payload?: IGroupEdit): Promise<AxiosResponse<IgetGroup>> {
        return $api.put<IgetGroup>('/service1/groups/' + groupId, payload, {withCredentials: true})
    }

    static async deleteGroup(groupId: string): Promise<AxiosResponse<IgetGroup>> {
        return $api.delete<IgetGroup>('/service1/groups/' + groupId)
    }

    static async addUserToGroup(email: string, group_id: string): Promise<AxiosResponse<IgetGroup>> {
        return $api.post<IgetGroup>('/service1/groups/addto', {email: email, group_id: group_id}, {withCredentials: true})
    }

    static async removeUserFromGroup(email: string, group_id: string): Promise<AxiosResponse<IRemoveUserFromGroup>> {
        return $api.post<IRemoveUserFromGroup>('/service1/groups/removefrom', {email: email, group_id: group_id}, {withCredentials: true})
    }

    static async getLogs(type: string): Promise<AxiosResponse<IgetLogs>> {
        return $api.get<IgetLogs>('/service1/groupslogs', {params: {type}});
    }

    static async getRecs(id: string): Promise<AxiosResponse<IgetRecs>> {
        return $api.get<IgetRecs>('/service1/groupsrec', {params: {id}});
    }

}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServerError } from '../../models/response/ServerError';
import SeansService from '../../services/SeansService.ts';
import { AxiosError } from 'axios';

export interface UserState {
  added: boolean;
  isLoading: boolean;
  seansList: any[];
  studentJoinLoading:boolean;
  oneSeansLoading: boolean;
  oneSeans: any;
}

const initialState: UserState = {
  added: false,
  isLoading: true,
  seansList: [],

  studentJoinLoading: false,
  oneSeansLoading: false,
  oneSeans: null
};

export const getAllSeans = createAsyncThunk(
  'seans/getAll',
  async () => {
    try {
      const response = await SeansService.getAllSeans();
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      throw e;
    }
  }
);
export const getOneSeans = createAsyncThunk(
  'seans/getone',
  async (data:string|undefined) => {
    try {
      const response = await SeansService.getOneSeans(data);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      throw e;
    }
  }
);
export const createSeans = createAsyncThunk(
  'seans/create',
  async (data:any, {getState}) => {
    try {
      // @ts-ignore
      const {user} = getState().UserSlice;
      const response = await SeansService.createSeans({...data, seanser: {firstName: user?.name, lastName: user?.sname} });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      throw e;
    }
  }
);

export const studentJoin = createAsyncThunk(
  'seans/student-join',
  async (data:any, {getState}) => {
    try {
      // @ts-ignore
      const {user} = getState().UserSlice;
      const response = await SeansService.studentJoin({seansId: data, studentId: user._id });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      const e = err.response?.data as ServerError;
      throw e;
    }
  }
);

export const SeansSlice = createSlice({
  name: 'seansSlice',
  initialState,
  reducers: {
    storeLoad(state) {
      state.isLoading = false;
    },
    // authUser(state, action) {
    //   state.user = {
    //     _id: (Date.now() + Math.random()).toString(),
    //     name: action.payload.name,
    //     sname: action.payload.sname,
    //     role: 'STUDENT',
    //   };
    // },
    // editUserNameAuth(state, action) {
    //   state.user.name = action.payload.name;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSeans.fulfilled, (state, { payload }) => {
         state.seansList = payload;
      })
      .addCase(createSeans.fulfilled, (state, { payload }) => {
         state.added = true;
      })
      .addCase(studentJoin.pending, (state, { payload }) => {
         state.studentJoinLoading = true;
      })
      .addCase(studentJoin.rejected, (state, { payload }) => {
         state.studentJoinLoading = false;
      })
      .addCase(studentJoin.fulfilled, (state, { payload }) => {
         state.studentJoinLoading = false;
      })
      .addCase(getOneSeans.pending, (state) => {
         state.oneSeansLoading = true;
         state.oneSeans = null;
      })
      .addCase(getOneSeans.fulfilled, (state, { payload }) => {
         state.oneSeansLoading = false;
         state.oneSeans = payload;
      })
  },
});

export default SeansSlice.reducer;
export const { /*authUser, editUserNameAuth*/ } = SeansSlice.actions;

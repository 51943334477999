import { ChangeEvent, FC } from 'react';
import { ITopMenu } from '../../../models/ITopMenu';
import MenuItemPermissions from './MenuItemPermissions';
import Input from '../Main/Input';
import debounce from 'lodash.debounce';
import { useAppSelector } from '../../../hooks/redux';
import {
  ITranslateItemString,
  translations,
} from '../../../utils/translations.tsx';
import React from 'react';

interface TopMenuPermissionsProps {
  menu?: ITopMenu[];
  fetchData: (role: string, search?: string, archive?: boolean) => Promise<void>;
}

const TopMenuPermissions: FC<TopMenuPermissionsProps> = ({
  menu,
  fetchData,
}) => {
  const { user } = useAppSelector((state) => state.UserSlice);
  const language = useAppSelector((state) => state.TranslateSlice.language);
  const SearchDebounce = debounce((e: string) => {
    void fetchData('', e, undefined);
  }, 1000);

  const {
    searchText,
  }: {
    searchText: ITranslateItemString;
  } = translations.messenger;
  const {
    archiveText,
    allText
  }: {
    archiveText: ITranslateItemString;
    allText: ITranslateItemString;
  } = translations.access;

  return (
    <>
      <div className="pt-16 md:pt-18 xl:pt-2 xl:bg-[#2c2c2c] xl:h-[90px] w-full flex flex-wrap xl:flex-nowrap  gap-1 xl:gap-0  py-1 xl:py-5 px-2 relative justify-between items-center">
        {menu &&
          menu.map((item) =>
            !item.scope ? (
              <div key={item.id}>
                {item.name === archiveText[language] ? (
                  <MenuItemPermissions
                    onClick={() => void fetchData('', undefined, true)}
                    item={item}
                  />
                ) : (
                  <MenuItemPermissions
                    onClick={() => void fetchData(item.path ? item.path : '', undefined, item.name !== allText[language] ? false : undefined)}
                    item={item}
                  />
                )}
              </div>
            ) : (
              item.scope.includes(user.role) && (
                <MenuItemPermissions
                  onClick={() => void fetchData(item.path ? item.path : '', undefined, item.name !== allText[language] ? false : undefined)}
                  key={item.id}
                  item={item}
                />
              )
            ),
          )}
        <div className="w-full absolute xl:static top-3 left-0 px-3">
          <Input
            className=""
            onChange={(e: ChangeEvent<HTMLInputElement>) => void SearchDebounce(e.target.value)}
            type="text"
            placeholder={searchText[language]}
          />
        </div>
      </div>
    </>
  );
};

export default TopMenuPermissions;

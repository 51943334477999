import { FC, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import OutlineButton from '../UI/OutlineButton';
import AddProgramThemeModal from '../Modals/AddProgramThemeModal';
import Theme from './Theme';
import { useParams } from 'react-router-dom';
import { Reorder } from "framer-motion"
import { setThemes } from '../../store/reducers/ProgramSlice';
import { ITheme } from '../../models/Program/ITheme';
import RemoveThemeModal from '../Modals/RemoveThemeModal';
import {ITranslateItemString, translations} from "../../utils/translations.tsx";

import "./Themes.css"

const Themes: FC = () => {
    const { groupId } = useParams();
    const [modal, setModal] = useState<boolean>(false);
    const [modal2, setModal2] = useState<boolean>(false);
    const [modal3, setModal3] = useState<boolean>(false);
    const [theme, setTheme] = useState<ITheme | undefined>();
    const { themes } = useAppSelector(state=> state.ProgramSlice);
    const { user } = useAppSelector(state=> state.UserSlice);
    const [active, setActive] = useState<string>();
    const dispatch = useAppDispatch();

    const language = useAppSelector(state => state.TranslateSlice.language)

    const {
        addThemeText,
    }: {
        addThemeText: ITranslateItemString,
    } = translations.schoolProgram

    const setThemesHandler = (e) => {
        dispatch(setThemes(e));
        
    }
    const editHandler = (theme: ITheme) => {
        setTheme(theme);
        setModal2(true);
    }

    const removeHandler = (theme: ITheme) => {
        setTheme(theme);
        setModal3(true);
    }

    const isMainPage = (user.role === 'DIRECTOR' || user.role === 'ZDIRECTOR') && !groupId

    return (
        <div className={'w-full h-full'}>
            {(isMainPage) &&
                <OutlineButton className='py-1 mb-4 !w-[400px] !text-base mx-auto'
                               onClick={() => setModal(true)}>{addThemeText[language]}</OutlineButton>
            }
            <div
                className='flex theme-container flex-col w-full items-center max-2xl:items-start  mr-2 xl:mr-28 2xl:mr-16 overflow-auto  h-full'>

                <Reorder.Group axis="y" values={themes} onReorder={setThemesHandler} className={'w-full'}>
                    {themes.map((theme) => (
                        <Theme editHandler={editHandler} removeHandler={removeHandler} active={active}
                               setActive={setActive} key={theme._id} theme={theme}/>
                    ))}
                </Reorder.Group>

                <AddProgramThemeModal modal={modal} setModal={setModal}/>
                <AddProgramThemeModal modal={modal2} setModal={setModal2} theme={theme} edit={true}/>
                {theme &&
                    <RemoveThemeModal modal={modal3} setModal={setModal3} theme={theme}/>
                }
            </div>
        </div>

    )
}

export default Themes;
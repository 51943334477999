import { FC, useState, useEffect } from 'react';
import TopMenu from '../components/UI/TopMenu/TopMenu';
import { ITopMenu } from '../models/ITopMenu';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import Filter from '../components/Program/Filter';
import Themes from '../components/Program/Themes';
import Materials from '../components/Program/Materials';
import { getThemes } from '../store/reducers/ProgramSlice';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ITranslateItemString, translations } from '../utils/translations.tsx';
import OutlineButton from '../components/UI/OutlineButton.tsx';
import TrainerAsseccModal from '../components/Modals/TrainerAsseccModal.tsx';

const ProgramPage: FC = () => {
  const { pathname } = useLocation();
  const { groupId } = useParams();
  const language = useAppSelector((state) => state.TranslateSlice.language);
  const { user } = useAppSelector((state) => state.UserSlice);

  const {
    programText,
    homeworkText,
    groupDescriptionText,
    historyText,
    onlineLessonText,
    participantsText,
    goTօOnlineLessonText,
  }: {
    programText: ITranslateItemString;
    homeworkText: ITranslateItemString;
    groupDescriptionText: ITranslateItemString;
    historyText: ITranslateItemString;
    onlineLessonText: ITranslateItemString;
    participantsText: ITranslateItemString;
    goTօOnlineLessonText: ITranslateItemString;
  } = translations.groups;
  const [menu] = useState<ITopMenu[]>(
    groupId
      ? [
          { id: 0, name: programText[language] },
          {
            id: 1,
            name: onlineLessonText[language],
            path: `/group/${groupId}/online-lesson`,
          }, // eslint-disable-line @typescript-eslint/restrict-template-expressions,
          {
            id: 2,
            name: homeworkText[language],
            path: `/group/${groupId}/homework`,
          }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
          { id: 3, name: participantsText[language] },
          { id: 4, name: groupDescriptionText[language] },
          {
            id: 5,
            name: historyText[language],
            path: `/group/${groupId}/history`,
          }, // eslint-disable-line @typescript-eslint/restrict-template-expressions
        ]
      : [],
  );
  const [modal, setModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getThemes({}));
    };
    void fetchData();
  }, [dispatch]);

  const isGroupPage = pathname.includes('group');

  const containerHight = isGroupPage
    ? 'h-[calc(100vh-124px)]'
    : 'h-[calc(100vh-60px)]';

  return (
    <div className="w-full flex  flex-col lg:overflow-y-auto h-full">
      <TopMenu menu={menu} />
      <div
        className={`flex ${containerHight}  flex-col bg-[#F0F0F0] rounded-3xl mx-3 my-3`}
      >
        <Filter />
        <Link
          to={`/group/${groupId}/online-lesson`}
          className={'py-2 xl:py-5 px-2 xl:px-10'}
        >
          <button
            type="button"
            className="w-full bg-gradient-menu text-white text-xl font-semibold py-3 px-4 rounded-full"
          >
            {goTօOnlineLessonText[language]}
          </button>
        </Link>
        {user?.role !== 'TRANER' && (
          <OutlineButton
            onClick={() => setModal(true)}
            className="w-1/2 mx-auto"
          >
            Доступ для тренеров
          </OutlineButton>
        )}
        <div className="flex h-full gap-3 justify-between px-2 xl:px-5 py-1 xl:py-3 overflow-y-auto min-h-[500px]">
          <div
            className={`w-full ${
              isGroupPage ? 'h-full' : 'h-[calc(100%-60px)]'
            }`}
          >
            <Themes />
          </div>
          <Materials />
        </div>
      </div>
      <TrainerAsseccModal modal={modal} setModal={setModal} />
    </div>
  );
};

export default ProgramPage;

import {FC, useEffect, useState} from 'react'
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import {useForm} from "react-hook-form";
import PermissionsService, {IPayload} from '../../services/PermissionsService';
import SuccessModal from './SuccessModal';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import CheckBox from '../UI/Main/Checkbox/CheckBox.tsx';
import {translations} from "../../utils/translations.tsx";
import {IUserData} from "../../models/IUserData.ts";
import {isUserDirector, UserRoles} from "../../utils/userRoles.ts";
import {basicChats, EGroupChatKeys} from "../../utils/basicChats.ts";
import {ECountries, User} from "../../models/User.ts";
import {getRequisites} from "../../store/reducers/BalansSlice.ts";
import AuthErrorModal from "./AuthError.tsx";

interface AddUserToAddGroupModalProps {
    modal: boolean,
    setModal: (bool: boolean) => void,
    onEditData: (user: User) => void,
    userdata: IUserData,
    openEditRequests: (id: string) => void,
}

interface IUserInfo {
    name: string,
    sname: string,
    tname: string,
    allgroups: boolean,
}


type IGroupChats = {
    [key in EGroupChatKeys]?: boolean
}

type Form = {
    group_id: string,
};


const {
    editDataText,
    nameText,
    surnameText,
    fatherNameText,
    roleText,
    directorText,
    zDirectorText,
    adminText,
    trainerText,
    studentText,
    newUserText,
    submitText,
    trainerMedhodistText,
    programmerText,
    dataChangedText,
} = translations.access


const PermissionsModal: FC<AddUserToAddGroupModalProps> = ({
                                                               modal,
                                                               setModal,
                                                               userdata,
                                                               onEditData,
                                                           }) => {

    const dispatch = useAppDispatch()
    const {user} = useAppSelector(state => state.UserSlice)
    const requisitesData = useAppSelector(state => state.BalanceSlice.requisites)

    const [modalError] = useState<string>('');
    const [role, setRole] = useState<UserRoles>(userdata.role);
    const [requizits, setRequizits] = useState<number>(userdata?.requizits || 0);
    const [eModal, setEModal] = useState<boolean>(false);
    const [sModal, setSModal] = useState<boolean>(false);

    useForm<Form>();

    const filteredGroupChats = basicChats
        .filter(item => !item.autoAdd && (!item.forRoles || item.forRoles.includes(role)))
        .sort((a, b) => (a?.fixedIndex?.[role] || a?.index?.[role] || 99) - +(b?.fixedIndex?.[role] || b?.index?.[role] || 99))

    const isNewRegisteredUser = basicChats.every(item => !userdata[item.key as keyof IUserData])

    const filteredGroupChatsObj = filteredGroupChats.reduce((acc: IGroupChats, cur) => {
        acc[cur.key] = ((isNewRegisteredUser || cur.key === EGroupChatKeys.testForNewTrainers) && cur.defaultActive && cur.defaultActive.includes(role)) || !!(userdata?.[cur.key as keyof IUserData])
        return acc;
    }, {})
    const [userInfo, setUserInfo] = useState<IUserInfo & IGroupChats>({
        name: '',
        sname: '',
        tname: '',
        seance: false,
        online: false,
        offline: false,
        allgroups: false,
        ...filteredGroupChatsObj
    });
    const language = useAppSelector(state => state.TranslateSlice.language)

    useEffect(() => {
        if (!requisitesData.length) dispatch(getRequisites())
    }, []);
    useEffect(() => {

        const {
            name,
            sname,
            tname,
            allgroups,
            techTrainer,
            techStudents,
            programmers,
            adminProblems,
            admins,
            trainerProblems,
            trainingProgram,
            araratInt,
            report,
            urgent,
            testForNewTrainers,
            groupForNewTrainers,
            importantNews,
            chatForChecks,
            armTrainers,
            rusTrainers,
            engTrainers,
            fraTrainers,
            gerTrainers,
            psycho,
            seance,
            online,
            offline,
            testLessonQuestions,
        } = userdata
        setUserInfo({
            name,
            sname,
            tname: tname || '',
            allgroups: !!(allgroups),

            // -----
            techTrainer: !!(techTrainer),
            psycho: !!(psycho),
            techStudents: !!(techStudents),
            programmers: !!(programmers),
            adminProblems: !!(adminProblems),
            admins: !!(admins),
            trainerProblems: !!(trainerProblems),
            trainingProgram: !!(trainingProgram),
            araratInt: !!(araratInt),
            report: !!(report),
            urgent: !!(urgent),
            importantNews: !!(importantNews),
            chatForChecks: !!(chatForChecks),
            testForNewTrainers: !!(testForNewTrainers),
            groupForNewTrainers: !!(groupForNewTrainers),
            armTrainers: !!(armTrainers),
            rusTrainers: !!(rusTrainers),
            engTrainers: !!(engTrainers),
            fraTrainers: !!(fraTrainers),
            gerTrainers: !!(gerTrainers),
            testLessonQuestions: !!(testLessonQuestions),
            seance: !!(seance),
            online: !!(online),
            offline: !!(offline),
        });
        setRole(userdata.role);
        if (userdata?.requizits) setRequizits(userdata.requizits);
        else if (userdata.country) {
            const countries = requisitesData.filter(item => item.countries.includes(userdata.country as ECountries)).sort((a, b) => a.users - b.users)
            if (countries[0]) setRequizits(countries[0].requisiteID)

        }
    }, [userdata])


    useEffect(() => {
        setUserInfo(prevState => ({
            ...prevState,
            ...filteredGroupChatsObj
        }))
    }, [role]);

    const submit = async () => {
        const data: IPayload = {
            name: userInfo.name,
            role: role,
            sname: userInfo.sname,
            allgroups: userInfo.allgroups,
            tname: userInfo.tname,
            requizits,

            //
            techTrainer: !!(userInfo.techTrainer),
            techStudents: !!(userInfo.techStudents),
            programmers: !!(userInfo.programmers),
            adminProblems: !!(userInfo.adminProblems),
            admins: !!(userInfo.admins),
            trainerProblems: !!(userInfo.trainerProblems),
            trainingProgram: !!(userInfo.trainingProgram),
            araratInt: !!(userInfo.araratInt),
            report: !!(userInfo.report),
            urgent: !!(userInfo.urgent),
            testForNewTrainers: !!(userInfo.testForNewTrainers),
            groupForNewTrainers: !!(userInfo.groupForNewTrainers),
            importantNews: !!(userInfo.importantNews),
            chatForChecks: !!(userInfo.chatForChecks),
            armTrainers: !!(userInfo.armTrainers),
            rusTrainers: !!(userInfo.rusTrainers),
            engTrainers: !!(userInfo.engTrainers),
            fraTrainers: !!(userInfo.fraTrainers),
            gerTrainers: !!(userInfo.gerTrainers),
            testLessonQuestions: !!(userInfo.testLessonQuestions),
            seance: !!(userInfo.seance),
            online: !!(userInfo.online),
            offline: !!(userInfo.offline),
            notifications: !!(userInfo.notifications),
            psycho: !!(userInfo.psycho),
        }
        await PermissionsService.setRole(userdata._id, data).then((res) => {
            setSModal(true);
            setModal(false);
            onEditData(res.data.user)
        });
    }
    return (
        <>
            <Modal active={modal} setActive={setModal} className='items-center'>
                <h1 className='text-2xl font-semibold tracking-wider dark:text-white text-gray-800 capitalize '>{editDataText[language]}</h1>
                <p className='text-center pt-5 mb-2 dark:text-white'>{userdata.name} {userdata.sname} {userdata.tname}</p>
                <Input wrapperClasses='mb-5 w-full' type='text' placeholder={nameText[language]}
                       label={`${nameText[language]}:`} value={userInfo.name}
                       onChange={e => setUserInfo({...userInfo, name: e.target.value})}/>
                <Input wrapperClasses='mb-5 w-full' type='text' placeholder={surnameText[language]}
                       label={`${surnameText[language]}:`} value={userInfo.sname}
                       onChange={e => setUserInfo({...userInfo, sname: e.target.value})}/>
                <Input wrapperClasses='mb-5 w-full' type='text' placeholder={fatherNameText[language]}
                       label={`${fatherNameText[language]}:`} value={userInfo.tname}
                       onChange={e => setUserInfo({...userInfo, tname: e.target.value})}/>

                <p className='text-left self-start dark:text-white'>{roleText[language]}:</p>
                <select onChange={e => setRole(e.target.value as UserRoles)}
                        className='border-2 border-black mb-5 w-full'>
                    {isUserDirector(user.role, true) &&
                        <>
                            <option selected={role === UserRoles.DIRECTOR}
                                    value={UserRoles.DIRECTOR}>{directorText[language]}</option>
                            <option selected={role === UserRoles.ZDIRECTOR}
                                    value={UserRoles.ZDIRECTOR}>{zDirectorText[language]}</option>
                            <option selected={role === UserRoles.ADMIN}
                                    value={UserRoles.ADMIN}>{adminText[language]}</option>
                            <option selected={role === UserRoles.TRANER}
                                    value={UserRoles.TRANER}>{trainerText[language]}</option>
                            <option selected={role === UserRoles.TRANERMETODIST}
                                    value={UserRoles.TRANERMETODIST}>{trainerMedhodistText[language]}</option>
                            <option selected={role === UserRoles.PROGRAMMER}
                                    value={UserRoles.PROGRAMMER}>{programmerText[language]}</option>
                        </>
                    }
                    <option selected={role === UserRoles.STUDENT}
                            value={UserRoles.STUDENT}>{studentText[language]}</option>
                    <option selected={role === UserRoles.NEWUSER}
                            value={UserRoles.NEWUSER}>{newUserText[language]}</option>
                </select>
                {(role === UserRoles.STUDENT) &&
                    <select
                        disabled={user.role === UserRoles.ADMIN}
                        onChange={e => setRequizits(+e.target.value)} className='border-2 border-black mb-5 w-full'>
                        {
                            requisitesData.map(({cardType, ownerEn, users, _id, requisiteID}) => (
                                <option
                                    key={_id}
                                    selected={requizits === requisiteID}
                                    value={requisiteID}
                                >{cardType} ({ownerEn}{user.role !== UserRoles.ADMIN ? ` | ${users} исп.` : ""})
                                </option>
                            ))
                        }
                    </select>
                }
                <div className="w-full">
                    {
                        filteredGroupChats.map(({key, text}) => (
                            <CheckBox
                                key={key}
                                labelClass={'max-w-full overflow-hidden text-ellipsis'}
                                checked={userInfo[key]}
                                onChange={() => setUserInfo({...userInfo, [key]: !userInfo[key]})}
                                wrapperClass='mb-3'
                                label={typeof text === 'string' ? text : text[language]}
                            />
                        ))
                    }
                </div>
                <Button onClick={submit}>{submitText[language]}</Button>
            </Modal>
            <AuthErrorModal modal={eModal} setModal={setEModal} error={modalError}/>
            <SuccessModal modal={sModal} setModal={setSModal} message={dataChangedText[language]}/>
        </>
    )
}

export default PermissionsModal;
import React from 'react'
import { Link } from 'react-router-dom'

const GameCard = () => {
  return (
    <div>
        <div className='rounded-t-lg py-2.5 px-8 border font-semibold text-lg bg-white w-36' >
            06:36
        </div>
        <div className='rounded-e-lg bg-white w-80' >
            <div className='flex flex-row items-center px-3 py-2' >
                <span className='rounded-full w-5 h-5 bg-green-400 mr-2' /><p>araratch</p>
            </div>
            <div className="px-3 py-2">
                btns
            </div>
            <div className='h-80' >
                <Link to={'s'} className="bg-gradient-button inline-block text- font-semibold rounded-full py-1 px-12 hover:bg-gradient-appricot text-center uppercase" >анализировать партию</Link>
            </div>
            <div className='flex flex-row items-center px-3 py-2' >
                <span className='rounded-full w-5 h-5 bg-green-400 mr-2' /><p>hrachya</p>
            </div>
        </div>
        <div className='rounded-b-lg py-2.5 px-8 border bg-green-400 font-semibold text-lg w-36' >
            07:15
        </div>
    </div>
  )
}

export default GameCard
import React from 'react';
import SeansTable from '../../../components/UI/tables/SeansTable';

const data = [
  {
    _id: 1,
    numberPlace: 1,
    fullName: 'Egor Gardeev',
    tranerName: 'Hrachya',
    group: 'Сб 14:30 З. Эд.',
    birthDate: '05/01/2015',
    gender: true,
    rating: 1500,
    points: 50,
    tranerMonthes: '4/4',
    groupMonthes: '4/4',
    marks: [3],
  },
  {
    _id: 2,
    numberPlace: 2,
    fullName: 'Egor Gardeev',
    tranerName: 'Hrachya',
    group: 'Сб 14:30 З. Эд.',
    birthDate: '05/01/2015',
    gender: true,
    rating: 1500,
    points: 50,
    tranerMonthes: '4/4',
    groupMonthes: '4/4',
    marks: [2],
  },
  {
    _id: 3,
    numberPlace: 3,
    fullName: 'Egor Gardeev',
    tranerName: 'Hrachya',
    group: 'Сб 14:30 З. Эд.',
    birthDate: '05/01/2015',
    gender: false,
    rating: 1500,
    points: 50,
    tranerMonthes: '4/4',
    groupMonthes: '4/4',
    marks: [1],
  },
  {
    _id: 4,
    numberPlace: 4,
    fullName: 'Egor Gardeev',
    tranerName: 'Hrachya',
    group: 'Сб 14:30 З. Эд.',
    birthDate: '05/01/2015',
    gender: true,
    rating: 1500,
    points: 50,
    tranerMonthes: '4/4',
    groupMonthes: '4/4',
    marks: [3],
  },
];

const SessionTablePage = () => {
  return (
    <div>
      <div>
        <div>
          <h2>СЕАНСИСТЫ</h2>
          <h3>Список Участников</h3>
        </div>
        <div>
          <button className="bg-gradient-button text-2xl font-semibold rounded-full py-4 px-12 hover:bg-gradient-red flex items-center">
            Мои ученики
          </button>
        </div>
      </div>

      <SeansTable data={data} />
    </div>
  );
};

export default SessionTablePage;
